import React, { useState } from "react";
import Navbar from "./components/upperMenu/Navbar";
import { Redirect } from "react-router";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import "./App.css";

import { DefaultTheme, ThemeProvider } from 'styled-components'
import light from "./styles/themes/light";
import dark from "./styles/themes/dark";

import GlobalStyle from "./styles/global";

import usePersistedState from "./utils/usePersistedState";

import Relatorios from "./pages/relatorio/relatorio";
import Configuracoes from "./pages/configuracoes/configuracoes";
import Ambientes from "./pages/ambientes/ambientes";
import Home from "./pages/home/home";
import HomeVapeDetect from "./pages/vapeDetect/home";
import AmbienteVapeDetect from "./pages/vapeDetect/ambientes";
import TodosOsGraficosVapeDetect from "./pages/vapeDetect/todosGraficos";
import TodosAmbientes from "./pages/todosAmbientes/todosAmbientes";
import TodosAmbientesVapeDetect from "./pages/vapeDetect/todosAmbientes";
import Notificacoes from "./pages/notificacoes/notificacoes";
import Login from "./pages/login/login";
import SideBarDataWeather from "./components/sideMenu/SidebarDataWeather";
import TodosGraficos from "./pages/todosGraficos/todosGraficos";
import ConfiguracaoDispositivo from "./pages/configuracoes/dispositivos/configuracaoDispositivos";
import ConfiguracaoAmbiente from "./pages/configuracoes/ambientes/configuracaoAmbientes";
import ConfiguracaoNotificacao from "./pages/configuracoes/notificacoes/configuracaoNotificacao";
import ConfiguracaoRegra from "./pages/configuracoes/regras/configuracaoRegra";
import VisaoMundo from "./pages/visaoMundo/visaoMundo";
import ConfiguracaoAtuador from "./pages/configuracoes/atuador/configuracaoAtuador";
import RelatorioVapeDetect from "./pages/vapeDetect/relatorio";
import Dashview from "./pages/dashview/dashview";

function isLoggedIn() {
  var authenticated = sessionStorage.getItem("userStatus");
  if (!authenticated) {
    return false;
  }

  return true;
}

function App() {
  const [theme, setTheme] = usePersistedState<DefaultTheme>('theme', light);

  const toggleTheme = () => {
    setTheme(theme.title === 'light' ? dark : light)
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Navbar toggleTheme={toggleTheme} />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route
            path="/relatorio"
            render={() => (isLoggedIn() ? <Relatorios /> : <Redirect to="/" />)}
          />

          <Route
            path="/notificacoes"
            render={() =>
              isLoggedIn() ? <Notificacoes /> : <Redirect to="/" />
            }
          />

          <Route
            path="/todosAmbientes"
            render={() =>
              isLoggedIn() ? <TodosAmbientes /> : <Redirect to="/" />
            }
          />

          <Route
            path="/visaoMundo"
            render={() => (isLoggedIn() ? <VisaoMundo /> : <Redirect to="/" />)}
          />

          <Route
            path="/todosGraficos"
            render={() =>
              isLoggedIn() ? <TodosGraficos /> : <Redirect to="/" />
            }
          />

          <Route
            path="/configuracoes"
            render={() =>
              isLoggedIn() ? <Configuracoes /> : <Redirect to="/" />
            }
          />

          <Route
            path="/dashview"
            render={() =>
              isLoggedIn() ? <Dashview /> : <Redirect to="/" />
            }
          />

          <Route
            path="/configuracao/dispositivos"
            render={() =>
              isLoggedIn() ? <ConfiguracaoDispositivo /> : <Redirect to="/" />
            }
          />

          <Route
            path="/configuracao/atuador"
            render={() =>
              isLoggedIn() ? <ConfiguracaoAtuador /> : <Redirect to="/" />
            }
          />

          <Route
            path="/configuracao/ambientes"
            render={() =>
              isLoggedIn() ? <ConfiguracaoAmbiente /> : <Redirect to="/" />
            }
          />

          <Route
            path="/configuracao/notificacao"
            render={() =>
              isLoggedIn() ? <ConfiguracaoNotificacao /> : <Redirect to="/" />
            }
          />

          <Route
            path="/configuracao/regra"
            render={() =>
              isLoggedIn() ? <ConfiguracaoRegra /> : <Redirect to="/" />
            }
          />

          <Route
            path="/ambientes"
            render={() => (isLoggedIn() ? <Ambientes /> : <Redirect to="/" />)}
          />

          <Route
            path="/home"
            render={() => (isLoggedIn() ? <Home /> : <Redirect to="/" />)}
          />

          <Route
            path="/vapeDetect/home"
            render={() => (isLoggedIn() ? <HomeVapeDetect /> : <Redirect to="/" />)}
          />

          <Route
            path="/vapeDetect/ambiente"
            render={() => (isLoggedIn() ? <AmbienteVapeDetect /> : <Redirect to="/" />)}
          />

          <Route
            path="/vapeDetect/todosGraficos"
            render={() => (isLoggedIn() ? <TodosOsGraficosVapeDetect /> : <Redirect to="/" />)}
          />

          <Route
            path="/vapeDetect/todosAmbientes"
            render={() => (isLoggedIn() ? <TodosAmbientesVapeDetect /> : <Redirect to="/" />)}
          />

          <Route
            path="/vapeDetect/relatorio"
            render={() => (isLoggedIn() ? <RelatorioVapeDetect /> : <Redirect to="/" />)}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
