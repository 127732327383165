import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight, FaCircle } from "react-icons/fa";
import Grid from "@mui/material/Grid";

const Carousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previousSlide = () => {
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex >= 0 ? newIndex : props.items.length - 1);
  };

  const nextSlide = () => {
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex < props.items.length ? newIndex : 0);
  };

  if (
    props.items.length > 0 &&
    currentIndex >= 0 &&
    currentIndex < props.items.length
  ) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FaAngleLeft
            size={50}
            color="#333"
            onClick={previousSlide}
            style={{ marginTop: "10%" }}
          />
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "10%" }}
          >
            {props.items[currentIndex].icon}
            <Grid item xs={12} sm={12} md={12}>
              <h5 style={{ marginLeft: "10px" }}>
                {props.items[currentIndex].text}
              </h5>
              <h6 style={{ marginLeft: "10px" }}>
                {props.items[currentIndex].hora.replaceAll("-", "/")}
              </h6>
            </Grid>
          </div>
          <FaAngleRight
            size={50}
            color="#333"
            onClick={nextSlide}
            style={{ marginTop: "10%" }}
          />
        </div>
        <div style={{ display: "flex", marginTop: "10%" }}>
          {props.items.map((item, index) => (
            <FaCircle
              key={index}
              size={10}
              color={currentIndex === index ? "#333" : "#bbb"}
              style={{ margin: "0 10px" }}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Carousel;
