// eslint-disable-next-line import/no-anonymous-default-export
export default {
    title: 'dark',

    colors: {
        primary: '#2E3B64',
        secondary: '#EDF3F8',
        contrast: '#486ADC',
        subcontrast: '#2E3B64',
        
        black: '#2C2C2C',
        white: '#FCFCFC',
        gray: '#7B7C88',
        
        background: '#202124',
        text: '#FCFCFC',
    },
    
    colors_components: {
        background: '#3C4042',
        text: '#FCFCFC',
        unavailable: '#2C2C2C',
    },

    colors_indicators: {
        unavailable: '#C2C9D1',
        regular: '#3DC13C',
        moderate: '#F3BB1B',
        bad: '#FD8E39',
        very_bad: '#202C33',
    },
};
