import React, { Fragment, useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Ambiente } from "../../models/Ambiente";
import Fade from "@mui/material/Fade";
import GridBox from "../gridBox/gridBox";
import Dropdown from "react-bootstrap/Dropdown";
import { Dispositivo } from "../../models/Dispositivo";
import { ThemeContext } from "styled-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Inter",
    padding: "2em",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2em",
    marginBottom: "2em",
  },
  textField: {
    marginBottom: "1em",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "18px",
  },
  header: {
    margin: "2em 0",
    fontWeight: "bold",
    color: "#333",
  },
  button: {
    width: "300px",
    height: "50px",
    borderRadius: "18px",
    color: "#fff",
    fontWeight: "bold",
    marginTop: "25px!Important",
  },
  logo: {
    width: "200px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    margin: "2em 0",
  },
});

const DispositivoForm = () => {
  const [ambientes, setAmbientes] = useState<Ambiente[]>([]);
  const [dispositivo, setDispositivo] = useState<Dispositivo[]>([]);
  const [loading, setLoading] = useState(false);
  const [identificacao, setIdentificacao] = useState(0);
  const [numeroSerie, setNumeroSerie] = useState("");
  const [idSala, setIdSala] = useState(0);
  const [idUsuario, setIdUsuario] = useState(0);

  const { colors_components } = useContext(ThemeContext);

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };
  const idUser = sessionStorage.getItem("sessionNumber");

  const [ambienteSelecionado, setAmbienteSelecionado] = useState("");

  const handleSelection = (nomeSala, id) => {
    setAmbienteSelecionado(nomeSala);
    setIdSala(id);
  };

  const handleEditClick = (dispositivo: Dispositivo) => {
    setIdentificacao(dispositivo.id);
    setNumeroSerie(dispositivo.serialnumber);
    setIdSala(dispositivo.id_ambientes);
  };

  const createFormObject = (identificacao, serialnumber, idSala) => {
    return {
      id: identificacao,
      serialnumber: serialnumber,
      idUsuario: sessionStorage.getItem("sessionNumber"),
      idAmbiente: idSala,
    };
  };

  const handleLoginRequest = async (dispositivo, method) => {
    if (method === "PUT") {
      axios
        .put(`${process.env.REACT_APP_URL_REQUEST}/dispositivo`, {
          headers: header,
          body: dispositivo,
        })
        .then((res) => {
          window.location.reload();
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_URL_REQUEST}/dispositivo`, {
          headers: header,
          body: dispositivo,
        })
        .then((res) => {
          window.location.reload();
        });
    }
  };

  /* verify if session contains an ambiente. If so, replace in the texts fields to allow update */
  useEffect(() => {
    if (sessionStorage.getItem("dispositivoEdicao") !== "{}") {
      var objeto = sessionStorage.getItem("dispositivoEdicao");
      let device = JSON.parse(objeto ? objeto : "{}");
      setIdentificacao(device.id);
      setNumeroSerie(device.serialnumber);
      setIdSala(device.id_ambientes);
      setAmbienteSelecionado(device.sala);
    }
  }, []);

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const device = createFormObject(identificacao, numeroSerie, idSala);
    if (sessionStorage.getItem("dispositivoEdicao") !== "{}") {
      handleLoginRequest(device, "PUT");
    } else {
      handleLoginRequest(device, "POST");
    }
  };

  useEffect(() => {
    const getAmbientes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/ambientes?idUsuario=${idUser}`,
          { headers: header }
        );
        setAmbientes(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmbientes();
  }, []);

  const classes = useStyles();

  return (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <div className={classes.root}>
        <GridBox
          sx={{
            width: "400px",
            height: "500px",
            background: colors_components.background,
            color: colors_components.text,
          }}
        >
          <h2 className={classes.form}>
            {" "}
            {sessionStorage.getItem("dispositivoEdicao") !== "{}"
              ? "Editar Dispositivo"
              : "Cadastrar Dispositivo"}
          </h2>
          <form className={classes.form} onSubmit={onSubmitForm}>
            <div hidden={sessionStorage.getItem("dispositivoEdicao") !== "{}"}>
              <h6
                style={{
                  textAlign: "left",
                  width: "300px",
                }}
              >
                Número de série:
              </h6>
              <TextField
                label="Numero de série"
                className={classes.textField}
                value={numeroSerie}
                onChange={(e) => setNumeroSerie(e.target.value)}
                required
              />
            </div>
            <h6
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Ambiente:
            </h6>
            <Dropdown style={{ width: "300px", marginTop: "25px!Important" }}>
              <Dropdown.Toggle id="dropdown-basic">
                {ambienteSelecionado
                  ? ambienteSelecionado
                  : "Selecione um ambiente"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {ambientes.map((ambiente) => (
                  <Dropdown.Item
                    key={ambiente.identificacao}
                    onClick={() =>
                      handleSelection(ambiente.sala, ambiente.identificacao)
                    }
                  >
                    {ambiente.sala}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={loading}
            >
              {sessionStorage.getItem("dispositivoEdicao") !== "{}"
                ? "Editar"
                : "Cadastrar"}
            </Button>

            <div
              className="mt-5"
              style={{ color: "#486ADC" }}
              onClick={() =>
                (window.location.href = "/configuracao/dispositivos")
              }
            >
              Fechar
            </div>
            <Fade in={loading}>
              <CircularProgress />
            </Fade>
          </form>
        </GridBox>
      </div>
    </Fragment>
  );
};

export default DispositivoForm;
