import React, { useContext} from "react";
import ApexCharts from "react-apexcharts";

import { ThemeContext } from "styled-components";


const GraficoSalas = (props) => {

  const { colors_components } = useContext(ThemeContext);

  var formatAxis = props?.formatAxis ? true : false;
  if (formatAxis) {
    props.options.xaxis.categories = props.options.xaxis.categories.map(
      (date) => {
        return date.replaceAll("-", "/");
      }
    );
  }

  props.options.title = {
    style: {
      color: colors_components.text
    }
  }

  /* set tooptip to show the hour */
  props.options.tooltip = {
    x: {
      format: "dd/MM/yy HH:mm",
      style: { color: "#fff" },
    },
  };

  /* round the y axis */
  props.options.yaxis = [
    {
      labels: {
        show: true,
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
  ];

  /* disable to show the value in a dot in the graph */
  props.options.dataLabels = {
    enabled: false,
  };

  return (
    <div
      id="chart"
      style={{
        backgroundColor: colors_components.background,
        borderRadius: "15px",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 2,
          blur: 4,
          opacity: 1,
        }
      }}
    >
      <ApexCharts
        options={props.options}
        series={props.options.series}
        type={props.type ? props.type : "area"}
        height={props.height ? props.height : "290px"}
      />
    </div>
  );
};

export default GraficoSalas;
