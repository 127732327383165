import React, { useContext} from "react";
import "./gridBox.css";
import { ThemeContext } from "styled-components";

type GridBoxProps = {
  sx?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
};

export default function GridBox(props: GridBoxProps) {

  const { colors_components } = useContext(ThemeContext);

  return (
    <div
      className={props.className ? props.className + " gridbox" : "gridBox"}
      style={{
        height: props.sx?.height ? props.sx?.height : "100%",
        width: props.sx?.width ? props.sx?.width : "100%",
        borderRadius: "18px",
        backgroundColor: colors_components.background,
        backgroundPosition: props.sx?.backgroundPosition ? props.sx?.backgroundPosition : "center",
        backgroundSize: props.sx?.backgroundSize ? props.sx?.backgroundSize : "cover",
        backgroundRepeat: props.sx?.backgroundRepeat ? props.sx?.backgroundRepeat : "no-repeat",
        backgroundImage: props.sx?.backgroundImage ? props.sx?.backgroundImage : "none",
        background: props.sx?.background ? props.sx?.background : colors_components.background,
        boxShadow: props.sx?.boxShadow ? props.sx?.boxShadow : "0 0 10px 0 rgba(0, 0, 0, 0.1)",
        margin: props.sx?.padding ? props.sx?.padding : "0px",
        /* set to increase onhover */
      }}
    >
      {props.children}
    </div>
  );
}

