import React, { useState, useEffect } from "react";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import MyGreatPlace from "./my_great_place.jsx";

export default function MapaQualidadeDoAr() {
  const [position, setPosition] = useState(null);
  const [isPositionLoaded, setIsPositionLoaded] = useState(false);
  const [mark, setMark] = useState([]);

  useEffect(() => {
    const header = {
      sessionToken: sessionStorage.getItem("sessionToken"),
    };

    const getMark = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/visaoMundo`,
          { headers: header }
        );

        setMark(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getMark();
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition(position);
        setIsPositionLoaded(true);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [mark]);

  const defaultProps = {
    center: {
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    },

    zoom: 11,
  };

  if (!isPositionLoaded) {
    // Position is not loaded yet, return a loading indicator or null
    return null;
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "650px", width: "100%" }} className={"mt-5"}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCQY2Y3WblN5k9bd6uue72KvLPpFKoh_9w" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {mark.map((marker) => (
          <MyGreatPlace
            lat={marker.latitude}
            lng={marker.longitude}
            text={marker.iaq ? marker.iaq.toFixed(0) : 0}
            color={marker.color}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}
