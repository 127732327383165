import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";
import logo from "../../public/logo.png"; // relative path to image
import ufg from "../../public/logoufg.png"; // relative path to image
import GraficoBarras from "../../components/graficoLinha/graficoBarras";
import { Icon } from "@iconify/react";
import {
  BiDevices,
  BiBell,
  BiDetail,
  BiUser,
  BiWifi,
  BiHome,
  BiTrendingDown,
  BiStopwatch,
  BiCloudSnow,
  BiTachometer
} from "react-icons/bi";
import Elderly from '@mui/icons-material/Elderly';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import Man2Icon from '@mui/icons-material/Man2';
import WomanIcon from '@mui/icons-material/Woman';
import FaBaby from '@mui/icons-material/BabyChangingStation';
import GridBox from "../../components/gridBox/gridBox";
import "../../App.css";
import ReactGA from "react-ga4";
import { ThemeContext } from "styled-components";
import { Amostragem } from "../../models/Amostragem";
import GaugeComponent from 'react-gauge-component'

function Dashview() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };

  const classificacaoIAQ = {
    50: "Bom",
    100: "Satisfatório",
    200: "Relativamente pobre",
    300: "Pobre",
    400: "Muito pobre",
    500: "Perigoso",
  };

  const dataAtual = new Date();
  const horaAtual = dataAtual.getHours();
  const mediasDiariasSimuladas = Array.from({ length: 11 }, (_, index) => {
    const hora = 7 + index; // Começando às 7h da manhã
    const quantidadePessoas = Math.round(Math.abs(Math.sin((hora / 24) * Math.PI) * 30) + Math.random() * 10);
    return {
      datamedicao: `${hora}:00`,
      quantidadePessoas,
    };
  });

  //Get id from url parameter
  const urlParams = new URLSearchParams(window.location.search);
  const idSala = urlParams.get('idSala');

  const { colors, colors_components } = useContext(ThemeContext);
  const [amostragens, setAmostragens] = useState<Amostragem[]>([]);
  const [pessoas, setPessoas] = useState<any>();
  const [masculino, setMasculino] = useState<any>();
  const [feminino, setFeminino] = useState<any>();
  const [jovem, setJovem] = useState<any>();
  const [adulto, setAdulto] = useState<any>();
  const [idoso, setIdoso] = useState<any>();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Configuração" });
  }, []);

  const [horaAtualGrid, setHoraAtualGrid] = useState(dataAtual.toLocaleTimeString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newDataAtual = new Date();
      setHoraAtualGrid(newDataAtual.toLocaleTimeString());
    }, 1000);

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Configuração",
    });

    // Limpeza do intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, []);

  //Creates a refresh on the page very 30 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      window.location.reload();
    }, 30000);

    // Limpeza do intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const getAmostragens = async () => {
      try {
        var dataTotal;
        await axios
          .get(
            `${process.env.REACT_APP_URL_REQUEST}/amostragens?idSala=${idSala}`,
            {
              headers: header,
            }
          )
          .then((data: any) => {
            dataTotal = data.data;
            for (let key in classificacaoIAQ) {
              const numericKey = parseInt(key);
              if (dataTotal[0].iaq <= numericKey) {
                dataTotal[0].statusIAQ = classificacaoIAQ[key];
                break;
              }
            }
          });

        setAmostragens(dataTotal);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmostragens();
  }, []);

  useEffect(() => {
    const getPessoas = async () => {
      try {
        var dataTotal;
        await axios
          .get(
            `${process.env.REACT_APP_URL_REQUEST}/contPessoas`,
            {
              headers: header,
            }
          ).then((data: any) => {
            dataTotal = data.data[0].total_registros;
            console.log(dataTotal);
            setPessoas(dataTotal);
            setMasculino(data.data[0].masculino);
            setFeminino(data.data[0].feminino);
            setJovem(data.data[0].jovem);
            setAdulto(data.data[0].adulto);
            setIdoso(data.data[0].idoso);
          });

      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getPessoas();
  }, []);

  const roundNumber = (value) => {
    return value.toFixed(2);
  }

  const getPercentOfCF = (cf: number) => {
    const thresholds = [
      { threshold: 5.9, percentage: "Muito frio" },
      { threshold: 8.9, percentage: "Muito frio" },
      { threshold: 11.9, percentage: "Frio" },
      { threshold: 14.9, percentage: "Frio" },
      { threshold: 17.9, percentage: "Confortável" },
      { threshold: 20.9, percentage: "Confortável" },
      { threshold: 23.9, percentage: "Quente" },
      { threshold: 26.9, percentage: "Quente" },
      { threshold: 29.9, percentage: "Muito quente" },
      { threshold: 32.9, percentage: "Muito quente" },
    ];

    for (const { threshold, percentage } of thresholds) {
      if (cf <= threshold) {
        return percentage;
      }
    }

    return 1.1; // Default percentage if none of the thresholds match
  };

  return (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="h1" style={{ marginTop: "20px", marginBottom: "10px", textAlign: "center" }}>
          Contador de Pessoas
        </h1>
        <h3 className="h3" style={{ marginTop: "20px", marginBottom: "50px", textAlign: "center" }}>
          Espaço das Profissões 2024
        </h3>

        <Grid
          className="text-center"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <GridBox

            >
              <BiStopwatch
                style={{
                  fontSize: "100px",
                  color: '#7B7C88',
                  marginTop: "10%",
                }}
              />
              <h3 className="h3" style={{ color: '#7B7C88' }}>
                Horário atual
              </h3>
              <h1 className="h1" style={{ color: colors_components.text }}>
                6:32:21 PM
              </h1>
            </GridBox>

          </Grid>
          <Grid item xs={12} sm={12} md={4}>

            <GridBox
              sx={{
                width: "100%",
                height: "350px",
                //background: "#F4F8FB",
              }}
            >
              <BiUser
                style={{
                  fontSize: "100px",
                  color: '#7B7C88',
                  marginTop: "10%",
                }}
              />
              <h3 className="h3" style={{ color: '#7B7C88' }}>
                Quantidade de Pessoas
              </h3>
              {
                //Escreve o dia de hoje
                <h5 className="h5" style={{ color: '#7B7C88' }}>
                  <small>{dataAtual.toLocaleDateString('PT-BR')}</small>
                </h5>
              }
              <h1 className="h1" style={{ color: colors_components.text }}>
                16216
              </h1>
            </GridBox>

          </Grid>

        </Grid>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img
          src={ufg} // Substitua pelo caminho real da sua imagem
          alt="Imagem"
          style={{ width: "4%", marginTop: "40px", marginRight: "30px", marginBottom: "50px",  display: "block" }}
        />
        <img
          src={logo} // Substitua pelo caminho real da sua imagem
          alt="Imagem"
          style={{ width: "7%", marginTop: "40px", marginBottom: "50px", display: "block" }}
        />

        </div>
      </div>
    </Fragment>
  );
}

export default Dashview;
