import React, { useContext} from "react";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { IconContext } from "react-icons";
import logo from "../../public/logo_wave.png"; // relative path to image

import Switch from "react-switch";
import { ThemeContext } from "styled-components";

interface Props {
  toggleTheme(): void;
}


function isLoggedIn(ignorarValidacao?: boolean) {
  var authenticated = sessionStorage.getItem("userStatus");
  if (!authenticated) {
    return "none";
  }

  if (sessionStorage.getItem("typeUser") == "smartAir" || ignorarValidacao) {
    return "block";
  } else {
    return "none";
  }
}



function Navbar( {toggleTheme} ) {

  const {colors, title} = useContext(ThemeContext);

  return (
    <>
      <IconContext.Provider value={{ color: colors.white }}>
        <div className="navbar" style={{ backgroundColor: colors.primary }}>
          <Link to={sessionStorage.getItem("typeUser") == "smartAir" ? "/home" : "/vapeDetect/home"} className="sidebar-itens">
            <div className="text-center">
              <img
                alt="Uma logomarca do INF"
                src={logo}
                style={{ width: "50px" }}
              />
            </div>
          </Link>
          <Link
            to="/"
            className="sidebar-itens sidebar-item5"
            style={{ display: isLoggedIn(true) }}
          >
            <AiIcons.AiOutlineLogout size={30} style={{ margin: "8px" }} />
          </Link>
          <Link
            to="/configuracoes"
            className="sidebar-itens sidebar-item2"
            style={{ display: isLoggedIn() }}
          >
            <AiIcons.AiFillSetting size={30} style={{ margin: "8px" }} />
          </Link>
          <Link
            to="/visaoMundo"
            className="sidebar-itens sidebar-item3"
            style={{ display: isLoggedIn() }}
          >
            <AiIcons.AiFillCompass size={30} style={{ margin: "8px" }} />
          </Link>
          <Link
            to="/dashview?idSala=2"
            className="sidebar-itens sidebar-item4"
            style={{ display: isLoggedIn() }}
          >
            <AiIcons.AiFillPieChart size={30} style={{ margin: "8px" }} />
          </Link>

          <div style={{ display: isLoggedIn(), padding: "15px"}}>
          <Switch
            onChange={toggleTheme}
            checked={title === 'dark'}
            checkedIcon={false}
            uncheckedIcon={false}
            height={15}
            width={35}
            handleDiameter={20}
            offColor={colors.gray}
            onColor={colors.contrast}
          />
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
