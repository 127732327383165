import React, { Fragment, useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Ambiente } from "../../models/Ambiente";
import Fade from "@mui/material/Fade";
import GridBox from "../gridBox/gridBox";
import Dropdown from "react-bootstrap/Dropdown";
import { Dispositivo } from "../../models/Dispositivo";
import { ThemeContext } from "styled-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Inter",
    padding: "2em",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2em",
  },
  textField: {
    marginBottom: "1em",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "18px",
  },
  header: {
    margin: "2em 0",
    fontWeight: "bold",
    color: "#333",
  },
  button: {
    width: "300px",
    height: "50px",
    borderRadius: "18px",
    color: "#fff",
    fontWeight: "bold",
    marginTop: "25px!Important",
  },
  logo: {
    width: "200px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    margin: "2em 0",
  },
});

const RegraForm = () => {
  const [ambientes, setAmbientes] = useState<Ambiente[]>([]);
  const [parametro, setParametro] = useState<string[]>([
    "temperatura",
    "umidade",
    "co2",
    "tvoc",
    "lux",
    "db",
  ]);

  /* create a key value list of importancia */
  const [importancia, setImportancia] = useState<string[]>([
    "Baixa",
    "Média",
    "Alta",
  ]);

  const [parametroSelecionado, setParametroSelecionado] = useState("");
  const [importanciaSelecionada, setImportanciaSelecionada] = useState("");
  const [loading, setLoading] = useState(false);
  const [identificacao, setIdentificacao] = useState(0);
  const [numeroSerie, setNumeroSerie] = useState("");
  const [valorMaximo, setValorMaximo] = useState("");
  const [valorMinimo, setValorMinimo] = useState("");
  const [tolerancia, setTolerancia] = useState("");
  const [idAmbiente, setIdAmbiente] = useState(0);
  const [id, setId] = useState(0);

  const { colors_components } = useContext(ThemeContext);

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };
  const idUser = sessionStorage.getItem("sessionNumber");

  const [ambienteSelecionado, setAmbienteSelecionado] = useState("");

  const handleSelection = (nomeSala, id) => {
    setAmbienteSelecionado(nomeSala);
    setIdAmbiente(id);
  };

  const handleSelectionParametro = (parametro) => {
    setParametroSelecionado(parametro);
  };

  const handleSelectionImportancia = (importancia) => {
    setImportanciaSelecionada(importancia);
  };

  const handleEditClick = (dispositivo: Dispositivo) => {
    setIdentificacao(dispositivo.id);
    setNumeroSerie(dispositivo.serialnumber);
    setIdAmbiente(dispositivo.id_ambientes);
  };

  const createFormObject = (
    identificacao,
    parametro,
    valorMaximo,
    valorMinimo,
    tolerancia,
    idAmbientes,
    importancia
  ) => {
    return {
      id: identificacao,
      parametro: parametro,
      maximo: valorMaximo,
      minimo: valorMinimo,
      tolerancia: tolerancia,
      id_ambientes: idAmbientes,
      importancia: importancia,
    };
  };

  const handleUpdateRequest = async (regra, method) => {
    if (method === "PUT") {
      axios
        .put(`${process.env.REACT_APP_URL_REQUEST}/regra`, {
          headers: header,
          body: regra,
        })
        .then((res) => {
          window.location.reload();
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_URL_REQUEST}/regra`, {
          headers: header,
          body: regra,
        })
        .then((res) => {
          window.location.reload();
        });
    }
  };

  /* verify if session contains an ambiente. If so, replace in the texts fields to allow update */
  useEffect(() => {
    if (sessionStorage.getItem("regraEdicao") !== "{}") {
      var objeto = sessionStorage.getItem("regraEdicao");
      let device = JSON.parse(objeto ? objeto : "{}");
      setParametroSelecionado(device.parametro);
      setValorMaximo(device.maximo);
      setValorMinimo(device.minimo);
      setIdentificacao(device.id);
      setTolerancia(device.tolerancia);
      setAmbienteSelecionado(device.sala);
      setIdAmbiente(device.id_ambientes);
      if (device.importancia === "green") {
        setImportanciaSelecionada("Baixa");
      } else if (device.importancia === "yellow") {
        setImportanciaSelecionada("Média");
      } else {
        setImportanciaSelecionada("Alta");
      }
    }
  }, []);

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const regra = createFormObject(
      identificacao,
      parametroSelecionado,
      valorMaximo,
      valorMinimo,
      tolerancia,
      idAmbiente,
      importanciaSelecionada
    );
    if (sessionStorage.getItem("regraEdicao") !== "{}") {
      handleUpdateRequest(regra, "PUT");
    } else {
      handleUpdateRequest(regra, "POST");
    }
  };

  useEffect(() => {
    const getAmbientes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/ambientes?idUsuario=${idUser}`,
          { headers: header }
        );
        setAmbientes(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmbientes();
  }, []);

  const classes = useStyles();

  return (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <div className={classes.root}>
        <GridBox
          sx={{
            width: "400px",
            height: "850px",
            background: colors_components.background,
            color: colors_components.text,
          }}
        >
          <h2 className={classes.form}>Regra</h2>
          <form className={classes.form} onSubmit={onSubmitForm}>
            <h6
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Ambiente:
            </h6>
            <Dropdown style={{ width: "300px", marginTop: "25px!Important" }}>
              <Dropdown.Toggle id="dropdown-basic">
                {ambienteSelecionado
                  ? ambienteSelecionado
                  : "Selecione um ambiente"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {ambientes.map((ambiente) => (
                  <Dropdown.Item
                    key={ambiente.identificacao}
                    onClick={() =>
                      handleSelection(ambiente.sala, ambiente.identificacao)
                    }
                  >
                    {ambiente.sala}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <h6
              className="mt-2"
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Parâmetro:
            </h6>
            <Dropdown style={{ width: "300px", marginTop: "25px!Important" }}>
              <Dropdown.Toggle id="dropdown-basic">
                {parametroSelecionado
                  ? parametroSelecionado
                  : "Selecione um parâmetro"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {parametro.map((parametro) => (
                  <Dropdown.Item
                    key={parametro}
                    onClick={() => handleSelectionParametro(parametro)}
                  >
                    {parametro}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <h6
              className="mt-2"
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Importância:
            </h6>
            <Dropdown style={{ width: "300px", marginTop: "25px!Important" }}>
              <Dropdown.Toggle id="dropdown-basic">
                {importanciaSelecionada
                  ? importanciaSelecionada
                  : "Selecione uma importância"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {importancia.map((importancia) => (
                  <Dropdown.Item
                    key={importancia}
                    onClick={() => handleSelectionImportancia(importancia)}
                  >
                    {importancia}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <h6
              className="mt-2"
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Valor máximo:
            </h6>
            <TextField
              label="Valor maximo"
              className={classes.textField}
              value={valorMaximo}
              onChange={(e) => setValorMaximo(e.target.value)}
              required
            />

            <h6
              className="mt-2"
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Valor mínimo:
            </h6>
            <TextField
              label="Valor minimo"
              className={classes.textField}
              value={valorMinimo}
              onChange={(e) => setValorMinimo(e.target.value)}
              required
            />

            <h6
              className="mt-2"
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Tolerância:
            </h6>
            <TextField
              label="tolerancia"
              className={classes.textField}
              value={tolerancia}
              onChange={(e) => setTolerancia(e.target.value)}
              required
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={loading}
            >
              {sessionStorage.getItem("regraEdicao") !== "{}"
                ? "Editar"
                : "Cadastrar"}
            </Button>

            <div
              className="mt-5"
              style={{ color: "#486ADC" }}
              onClick={() => (window.location.href = "/configuracao/regra")}
            >
              Fechar
            </div>
            <Fade in={loading}>
              <CircularProgress />
            </Fade>
          </form>
        </GridBox>
      </div>
    </Fragment>
  );
};

export default RegraForm;
