import React, { useState } from 'react';
import ApexCharts from 'react-apexcharts';

const GraficoBarras = (props) => {

  var formatAxis = props?.formatAxis ? true : false;
  if (formatAxis) {
    props.options.xaxis.categories = props.options.xaxis.categories.map(
      (date) => {
        return date.replaceAll("-", "/");
      }
    );
  }

  return (
    <div>
      <ApexCharts options={props.options} series={props.options.series} type="bar" height={290} />
    </div>
  );
};

export default GraficoBarras;
