// eslint-disable-next-line import/no-anonymous-default-export
export default {
    title: 'light',

    colors: {
        primary: '#486ADC',
        secondary: '#EDF3F8',
        contrast: '#486ADC',
        subcontrast: '#8CEDCC',
        
        black: '#2C2C2C',
        white: '#FCFCFC',
        gray: '#7B7C88',
        
        background: '#E7E8F3',
        text: '#2C2C2C',
    },

    colors_components: {
        background: '#F4F8FB',
        text: '#2C2C2C',
        unavailable: '#CCCCCC',
    },

    colors_indicators: {
        unavailable: '#C2C9D1',
        regular: '#3DC13C',
        moderate: '#F3BB1B',
        bad: '#FD8E39',
        very_bad: '#202C33',
    },
};
