import React, { Fragment, useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { WiThermometer, WiRaindrop, WiStrongWind } from "react-icons/wi";
import { TiWiFi } from "react-icons/ti";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import "../../App.css";
import "../../styles/toggle.css";
import GridBox from "../../components/gridBox/gridBox";
import PieChart from "../../components/pieChart/pieChart";
import GraficoBarras from "../../components/graficoLinha/graficoBarras";
import Carousel from "../../components/carousel/carousel";
import { Notificacao } from "../../models/Notificacoes";
import { Amostragem } from "../../models/Amostragem";
import { Atuadores } from "../../models/Atuadores";
import GraficoSalas from "../../components/graficoLinha/graficoSalas";
import Toggle from "react-toggle";
import mqtt from "mqtt/dist/mqtt";
import * as AiIcons from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Divider } from "antd";
import ReactGA from "react-ga4";
import { ThemeContext } from "styled-components";
import { colors } from "@mui/material";

var client = mqtt.connect("wss://test.mosquitto.org:8081");





function Ambientes() {

  const { colors_components } = useContext(ThemeContext);
  ReactGA.initialize("G-J2RWZ9FWRN");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Visualização - Ambiente",
    });
  }, []);

  client.on("message", (topic, payload) => {
    console.log(payload.toString());
  });

  client.on("connect", () => {
    client.subscribe("topic/smartair/comandos/99");
    console.log("Connected to MQTT Broker.");

    const legendTextElements = document.querySelectorAll('.apexcharts-legend-text');

    // Iterate through each element and apply the style
    legendTextElements.forEach((legendText) => {
      if (legendText instanceof HTMLElement) {
        legendText.style.color = colors_components.text;
        legendText.style.fill = colors_components.text;
      }
    });
  });

  useEffect(() => {
    var legendTextElements = document.querySelectorAll('.apexcharts-legend-text');

    // Iterate through each element and apply the style
    legendTextElements.forEach((legendText) => {
      if (legendText instanceof HTMLElement) {
        legendText.style.color = colors_components.text;
        legendText.style.fill = colors_components.text;
      }
    });
  }, [colors_components.text]);
  const [amostragens, setAmostragens] = useState<Amostragem[]>([]);
  var [atuadores, setAtuadores] = useState<Atuadores[]>([]);
  const [tipoNotificacoes, setTipoNotificacoes] = useState<Notificacao[]>([]);
  const [mediasDiarias, setMediasDiarias] = useState<Amostragem[]>([]);
  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
  const [iaqAtual, setIaqAtual] = useState<Number>(0);
  var classificacao = "";

  const [isLoading, setIsLoading] = useState(true);

  function handleColors(valor, parametro) {
    if (parametro === "temperatura") {
      if (valor > 35) {
        return "#FF0000";
      } else if (valor > 33) {
        return "#BBBB00";
      } else {
        return "#128D15";
      }
    } else if (parametro === "umidade") {
      if (valor > 70) {
        return "#FF0000";
      } else if (valor > 40) {
        return "#BBBB00";
      } else {
        return "#128D15";
      }
    } else {
      if (valor > 100) {
        return "#FF0000";
      } else if (valor > 50) {
        return "#BBBB00";
      } else {
        return "#00FF00";
      }
    }
  }

  function handleToggle(atuador) {
    atuador.ultimo_status = !atuador.ultimo_status;
    setAtuadores([...atuadores]);

    const sala: any = JSON.parse(sessionStorage.getItem("sala") || "{}");
    const getTipoNotificacoes = async () => {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_URL_REQUEST}/atuadores?idSala=${sala.identificacao
          }&idAtuador=${atuador.identificacao}&status=${atuador.ultimo_status ? "true" : "false"
          }`,
          { headers: header }
        );
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getTipoNotificacoes();

    console.log(
      "Publicando no tópico: topic/smartair/comandos/" + atuador.serialnumber
    );

    client.publish(
      "topic/smartair/comandos/" + atuador.serialnumber,
      atuador.ultimo_status ? "power" : "power"
    );
  }

  const icons = {
    "Detecção elevada de poluentes": <WiStrongWind size={110} color="#000000" />,
    "Dispositivo offline": <TiWiFi size={110} color="#FF0000" />,
  };

  const classificacaoIAQ = {
    50: "Bom",
    100: "Satisfatório",
    200: "Relativamente pobre",
    300: "Pobre",
    400: "Muito pobre",
    500: "Perigoso",
  };

  const idUser = sessionStorage.getItem("sessionNumber");
  const idSala = sessionStorage.getItem("idSala");
  const nomeSala = sessionStorage.getItem("salaAtual");

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };

  useEffect(() => {
    const getTipoNotificacoes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/notificacoes/tipo/${idUser}`,
          { headers: header }
        );
        setTipoNotificacoes(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getTipoNotificacoes();
  }, []);

  useEffect(() => {
    const getAtuadores = async () => {
      try {
        var { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/atuadores?idSala=${idSala}`,
          {
            headers: header,
          }
        );
        setAtuadores(data);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAtuadores();
  }, []);

  useEffect(() => {
    const getAmostragens = async () => {
      try {
        var dataTotal;
        await axios
          .get(
            `${process.env.REACT_APP_URL_REQUEST}/amostragens?idSala=${idSala}`,
            {
              headers: header,
            }
          )
          .then((data: any) => {
            dataTotal = data.data;
            for (let key in classificacaoIAQ) {
              const numericKey = parseInt(key);
              if (dataTotal[0].iaq <= numericKey) {
                dataTotal[0].statusIAQ = classificacaoIAQ[key];
                break;
              }
            }
          });

        setAmostragens(dataTotal);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmostragens();
  }, []);

  useEffect(() => {
    const getMediasDiarias = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/mediaDiaria/10/${idSala}`,
          { headers: header }
        );
  
        // Convertendo a data para um formato reconhecível
        const formattedData = data.map(item => {
          const [dia, mes] = item.datamedicao.split('-'); // Separando o dia e o mês
          const anoAtual = new Date().getFullYear(); // Obtendo o ano atual
          const dataFormatada = new Date(anoAtual, parseInt(mes) - 1, parseInt(dia)); // Criando um objeto Date com a data formatada
          return { ...item, datamedicao: dataFormatada }; // Retornando o objeto com a data formatada
        });
  
        // Ordenando os dados pela data
        formattedData.sort((a, b) => a.datamedicao - b.datamedicao);

        // transforma datamedicao no formato dd-mm de novo
        formattedData.forEach(item => {
          const dia = item.datamedicao.getDate().toString().padStart(2, '0');
          const mes = (item.datamedicao.getMonth() + 1).toString().padStart(2, '0');
          item.datamedicao = `${dia}-${mes}`;
        });
        
        setMediasDiarias(formattedData);
      } catch (err) {
        console.error(`Erro inesperado: ${err}`);
      }
    };
  
    getMediasDiarias();
  }, []);
  

  useEffect(() => {
    const getNotificacoes = async () => {
      try {
        var { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/notificacoes/${idUser}`,
          { headers: header }
        );

        console.log(data);
        console.log(nomeSala)

        /* filter by sala */
        data = data.filter((notificacao) => {
          return notificacao.sala == nomeSala;
        });

        data = data.slice(0, 4);

        console.log(data);

        setNotificacoes(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getNotificacoes();
  }, []);

  return isLoading !== true ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Dashboard</h1>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h3 className="header-text h3">{nomeSala}</h3>

          <Link style={{ color: "#486ADC" }} to={"/vapeDetect/relatorio"}>
            Visualizar relatório
          </Link>
        </Grid>
        <Grid
          className="text-center"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
      

          <Grid item xs={12} sm={12} md={6}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <h5 className="text-center header-text valores pt-4">
                Poluentes
                <small>
                  <span
                    data-tooltip-id="tooltip-co2"
                    data-tooltip-content="O nível de TVOC indica possíveis utilizações de cigarros."
                  >
                    <AiIcons.AiOutlineQuestionCircle />
                  </span>
                </small>
                <Tooltip className="mw-20" id="tooltip-co2" />
              </h5>
              <small>Média dos últimos 10 dias</small>

              <GraficoSalas
                options={{
                  chart: {
                    type: "line",
                  },
                  series: [
                    {
                      name: "CO2",
                      data: mediasDiarias.map((variacao) => {
                        return variacao.tvoc;
                      }),
                    },
                  ],
                  xaxis: {
                    categories: mediasDiarias.map((variacao) => {
                      return variacao.datamedicao;
                    }),
                  },
                }}
                formatAxis={true}
                height={230}
              />
              <Grid
                className="ml-2"
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Link to="/vapeDetect/todosGraficos">
                    <h6 style={{ position: "absolute", color: "#486ADC" }}>
                      Ver variação diária
                    </h6>
                  </Link>
                </Grid>
              </Grid>
            </GridBox>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <h5 className="text-center header-text valores pt-4">
                Últimos alertas
              </h5>
              <div style={{ paddingTop: "10%" }}>
                <Carousel
                  items={notificacoes.map((notificacao) => {
                    return {
                      icon: icons[notificacao.aviso],
                      text: notificacao.aviso,
                      hora: notificacao.data,
                    };
                  })}
                />
              </div>
            </GridBox>
          </Grid>
        
        </Grid>
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default Ambientes;
