import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {
  WiThermometer,
  WiHumidity,
  WiStrongWind,
  WiCloud,
} from "react-icons/wi";
import ReactGA from "react-ga4";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { TiWiFi } from "react-icons/ti";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import "../../App.css";
import GridBox from "../../components/gridBox/gridBox";
import PieChart from "../../components/pieChart/pieChart";
import GraficoBarras from "../../components/graficoLinha/graficoBarras";
import Carousel from "../../components/carousel/carousel";
import { Notificacao } from "../../models/Notificacoes";
import { Amostragem } from "../../models/Amostragem";
import GraficoSalas from "../../components/graficoLinha/graficoSalas";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Relatorio } from "../../models/Relatorio";
import { Ambiente } from "../../models/Ambiente";

function Relatorios() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Relatório" });
  }, []);
  const [tipoNotificacoes, setTipoNotificacoes] = useState<Notificacao[]>([]);
  const [mediasDiarias, setMediasDiarias] = useState<Amostragem[]>([]);
  const [mediasDiariasVapeDetect, setMediasDiariasVapeDetect] = useState<any>([]);
  const [relatorio, setRelatorio] = useState<Relatorio[]>([]);
  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
  const [mesAnoAtual, setMesAnoAtual] = useState<String>("");
  const [isLoading, setIsLoading] = useState(true);

  const classificacaoIAQ = {
    50: "Bom",
    100: "Satisfatório",
    200: "Relativamente pobre",
    300: "Pobre",
    400: "Muito pobre",
    500: "Perigoso",
  };

  const idUser = sessionStorage.getItem("sessionNumber");
  const idSala = sessionStorage.getItem("idSala");
  const nomeSala = sessionStorage.getItem("salaAtual");
  const [sala, setSala] = useState<Ambiente[]>([]);

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };

  useEffect(() => {
    setSala([JSON.parse(sessionStorage.getItem("sala") || "{}")]);
  }, []);

  useEffect(() => {
    const getMediasDiarias = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/mediaMensal?idAmbiente=${idSala}`,
          { headers: header }
        );

        setMediasDiarias(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getMediasDiarias();
  }, []);

  useEffect(() => {
    const getMediasDiariasNotificacoes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/mediaMensal/vapeDetect?idAmbiente=${idSala}`,
          { headers: header }
        );

        setMediasDiariasVapeDetect(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getMediasDiariasNotificacoes();
  }, []);


  useEffect(() => {
    const getRelatorio = async () => {
      try {
        var dataTotal;
        var dataAtual = new Date();
        var mesAtual =
          dataAtual.getMonth() + 1 < 10
            ? "0" + (dataAtual.getMonth() + 1)
            : dataAtual.getMonth() + 1;
        var anoAtual = dataAtual.getFullYear();
        setMesAnoAtual(mesAtual + "/" + anoAtual);

        await axios
          .get(
            `${process.env.REACT_APP_URL_REQUEST}/vapeDetect/relatorio?id=${idSala}&mes=${mesAtual + "/" + anoAtual}`,
            { headers: header }
          )
          .then((data: any) => {
            dataTotal = data.data;
            for (let key in classificacaoIAQ) {
              const numericKey = parseInt(key);
              if (dataTotal[0].media_iaq <= numericKey) {
                dataTotal[0].statusIAQ = classificacaoIAQ[key];
                break;
              }
            }
          });

        setRelatorio(dataTotal);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getRelatorio();
    setIsLoading(false);
  }, []);



  return isLoading !== true ? (
    <div id="relatorio">
      <Fragment>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
          rel="stylesheet"
        />

        <div className="container">
          <h1 className="mt-5 header-text h1">Relatório</h1>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3 className="header-text h3">Sala: {nomeSala} <small style={{ fontSize: "12px" }}>Referente ao mês: {mesAnoAtual}</small></h3>

            <Link
              style={{ color: "#486ADC" }}
              to="#"
              onClick={() => {
                html2canvas(document.getElementById("relatorio")!, {
                  allowTaint: true,
                  useCORS: true,
                }).then(function (canvas) {
                  var img = canvas.toDataURL("image/png", 1.0);
                  var doc = new jsPDF("landscape", "pt", "a4");
                  var width = doc.internal.pageSize.width;
                  var height = doc.internal.pageSize.height;
                  doc.addImage(img, "JPEG", 0, 0, width, height);
                  doc.save("Relatorio.pdf");
                });
              }}
            >
              Imprimir relatório
            </Link>
          </Grid>
          <Grid
            className="text-center"
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >


            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "start" }}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <div className="pl-3">
                  <h5 className="pt-3">Média dos poluentes no mês:</h5>
                  {relatorio.map((relatorio, index) => (
                    <div key={index}>
                      <h3 className="valores">
                        {relatorio.media_tvoc ? relatorio.media_tvoc.toFixed(2) : relatorio.media_tvoc} ppm{" "}
                        {relatorio.tvoc_mes_atual_porcentagem > 0 ? (
                          <FaArrowAltCircleUp color="#FF0000" />
                        ) : (
                          <FaArrowAltCircleDown color="#FF0000" />
                        )}{" "}
                      </h3>
                      <small style={{ fontSize: "12px" }}>
                        {Math.abs(relatorio.tvoc_mes_atual_porcentagem ? relatorio.tvoc_mes_atual_porcentagem : 0).toFixed(2)}%
                        {relatorio.tvoc_mes_atual_porcentagem > 0 ? " a mais que " : " a menos que "} o mes anterior
                      </small>
                    </div>
                  ))}
                </div>
                <GraficoSalas
                  height="220px"
                  options={{
                    chart: {
                      type: "line",
                    },
                    series: [
                      {
                        name: "TVOC",
                        data: mediasDiarias.map((variacao) => {
                          return variacao.tvoc;
                        }),
                      },
                    ],
                    xaxis: {
                      categories: mediasDiarias.map((variacao) => {
                        return variacao.datamedicao;
                      }),
                    },
                    colors: ["#00AA00"],
                  }}
                  formatAxis={false}
                />
              </GridBox>
            </Grid>

            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "start" }}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <div className="pl-3">
                  <h5 className="pt-3">Quantidade de alertas no mês:</h5>
                </div>
                <GraficoSalas
                  height="220px"
                  options={{
                    chart: {
                      type: "line",
                    },
                    series: [
                      {
                        name: "TVOC",
                        data: mediasDiariasVapeDetect.map((variacao) => {
                          return variacao.quantidade;
                        }),
                      },
                    ],
                    xaxis: {
                      categories: mediasDiariasVapeDetect.map((variacao) => {
                        return variacao.datamedicao;
                      }),
                    },
                    colors: ["#00AA00"],
                  }}
                  formatAxis={false}
                />
              </GridBox>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    </div>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default Relatorios;
