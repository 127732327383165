import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import "../../../App.css";
import axios from "axios";
import TabelaHome from "../../../components/tabela/tabelaHome";
import CircularProgress from "@mui/material/CircularProgress";
import DispositivoForm from "../../../components/forms/dispositivo";
import { Dispositivo } from "../../../models/Dispositivo";
import { Atuadores } from "../../../models/Atuadores";
import AtuadorForm from "../../../components/forms/atuador";

function ConfiguracaoAtuador() {
  const [atuadores, setAtuadores] = useState<Atuadores[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };
  const idUser = sessionStorage.getItem("sessionNumber");

  useEffect(() => {
    const getAmbientes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/atuadores?idUsuario=${idUser}`,
          { headers: header }
        );
        setAtuadores(data);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmbientes();
  }, []);

  return !isLoading ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Configurações</h1>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h3 className="header-text h3">Atuador</h3>
          <Link
            to={"#"}
            onClick={() => {
              setShowForm(!showForm);
              sessionStorage.setItem("tipoEdicao", "{}");
            }}
            style={{ color: "#486ADC" }}
          >
            Cadastrar Atuadores
          </Link>
        </Grid>
        {showForm && (
          <div className="expanded-form">
            <AtuadorForm />
          </div>
        )}
        <Grid
          className="text-center"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <TabelaHome
            height="300%"
            width="100%"
            columns={[
              {
                title: "Nome",
                dataIndex: "nome",
                key: "nome",
              },
              {
                title: "Tipo",
                dataIndex: "tipo",
                key: "tipo",
              },
              {
                title: "Sala",
                dataIndex: "sala",
                key: "sala",
              },
              {
                title: "Editar",
                dataIndex: "editar",
                key: "editar",
              },
              {
                title: "Excluir",
                dataIndex: "excluir",
                key: "excluir",
              },
            ]}
            dataSource={atuadores.map((atuador) => {
              return {
                key: atuador.identificacao,
                nome: atuador.nome,
                tipo: atuador.tipo,
                sala: atuador.sala,
                editar: (
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      /* set an object in session */
                      console.log(atuador);
                      sessionStorage.setItem(
                        "tipoEdicao",
                        JSON.stringify(atuador)
                      );
                      setShowForm(true);
                    }}
                  >
                    Editar
                  </button>
                ),
                excluir: (
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      /* ask if user is sure  */
                      if (window.confirm("Tem certeza que deseja excluir?")) {
                        axios
                          .delete(
                            `${process.env.REACT_APP_URL_REQUEST}/atuadores?identificacao=${atuador.identificacao}`,
                            { headers: header }
                          )
                          .then((res) => {
                            window.location.reload();
                          });
                      }
                    }}
                  >
                    Excluir
                  </button>
                ),
              };
            })}
          />
        </Grid>
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default ConfiguracaoAtuador;
