import React, { Fragment, useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { WiThermometer, WiRaindrop, WiStrongWind } from "react-icons/wi";
import { TiWiFi } from "react-icons/ti";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import "../../App.css";
import "../../styles/toggle.css";
import GridBox from "../../components/gridBox/gridBox";
import PieChart from "../../components/pieChart/pieChart";
import GraficoBarras from "../../components/graficoLinha/graficoBarras";
import Carousel from "../../components/carousel/carousel";
import { Notificacao } from "../../models/Notificacoes";
import { Amostragem } from "../../models/Amostragem";
import { Atuadores } from "../../models/Atuadores";
import GraficoSalas from "../../components/graficoLinha/graficoSalas";
import Toggle from "react-toggle";
import mqtt from "mqtt/dist/mqtt";
import * as AiIcons from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Divider } from "antd";
import ReactGA from "react-ga4";
import { ThemeContext } from "styled-components";
import { colors } from "@mui/material";

var client = mqtt.connect("wss://test.mosquitto.org:8081");





function Ambientes() {

  const { colors_components } = useContext(ThemeContext);
  ReactGA.initialize("G-J2RWZ9FWRN");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Visualização - Ambiente",
    });
  }, []);

  client.on("message", (topic, payload) => {
    console.log(payload.toString());
  });

  client.on("connect", () => {
    client.subscribe("topic/smartair/comandos/99");
    console.log("Connected to MQTT Broker.");

    const legendTextElements = document.querySelectorAll('.apexcharts-legend-text');

    // Iterate through each element and apply the style
    legendTextElements.forEach((legendText) => {
      if (legendText instanceof HTMLElement) {
        legendText.style.color = colors_components.text;
        legendText.style.fill = colors_components.text;
      }
    });
  });

  useEffect(() => {
    var legendTextElements = document.querySelectorAll('.apexcharts-legend-text');

    // Iterate through each element and apply the style
    legendTextElements.forEach((legendText) => {
      if (legendText instanceof HTMLElement) {
        legendText.style.color = colors_components.text;
        legendText.style.fill = colors_components.text;
      }
    });
  }, [colors_components.text]);
  const [amostragens, setAmostragens] = useState<Amostragem[]>([]);
  var [atuadores, setAtuadores] = useState<Atuadores[]>([]);
  const [tipoNotificacoes, setTipoNotificacoes] = useState<Notificacao[]>([]);
  const [mediasDiarias, setMediasDiarias] = useState<Amostragem[]>([]);
  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
  const [iaqAtual, setIaqAtual] = useState<Number>(0);
  var classificacao = "";

  const [isLoading, setIsLoading] = useState(true);

  function handleColors(valor, parametro) {
    if (parametro === "temperatura") {
      if (valor > 35) {
        return "#FF0000";
      } else if (valor > 33) {
        return "#BBBB00";
      } else {
        return "#128D15";
      }
    } else if (parametro === "umidade") {
      if (valor > 70) {
        return "#FF0000";
      } else if (valor > 40) {
        return "#BBBB00";
      } else {
        return "#128D15";
      }
    } else {
      if (valor > 100) {
        return "#FF0000";
      } else if (valor > 50) {
        return "#BBBB00";
      } else {
        return "#00FF00";
      }
    }
  }

  function handleToggle(atuador) {
    atuador.ultimo_status = !atuador.ultimo_status;
    setAtuadores([...atuadores]);

    const sala: any = JSON.parse(sessionStorage.getItem("sala") || "{}");
    const getTipoNotificacoes = async () => {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_URL_REQUEST}/atuadores?idSala=${sala.identificacao
          }&idAtuador=${atuador.identificacao}&status=${atuador.ultimo_status ? "true" : "false"
          }`,
          { headers: header }
        );
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getTipoNotificacoes();

    console.log(
      "Publicando no tópico: topic/smartair/comandos/" + atuador.serialnumber
    );

    client.publish(
      "topic/smartair/comandos/" + atuador.serialnumber,
      atuador.ultimo_status ? "power" : "power"
    );
  }

  const icons = {
    "Alto nível de temperatura": <WiThermometer size={110} color="#FF0000" />,
    "Baixo nível de temperatura": <WiThermometer size={110} color="#0000FF" />,
    "Alto nível de umidade": <WiRaindrop size={110} color="#FF0000" />,
    "Baixo nível de umidade": <WiRaindrop size={110} color="#0000FF" />,
    "Qualidade do ar": <WiStrongWind size={110} color="#000000" />,
    "Dispositivo offline": <TiWiFi size={110} color="#FF0000" />,
  };

  const classificacaoIAQ = {
    50: "Bom",
    100: "Satisfatório",
    200: "Relativamente pobre",
    300: "Pobre",
    400: "Muito pobre",
    500: "Perigoso",
  };

  const idUser = sessionStorage.getItem("sessionNumber");
  const idSala = sessionStorage.getItem("idSala");
  const nomeSala = sessionStorage.getItem("salaAtual");

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };

  useEffect(() => {
    const getTipoNotificacoes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/notificacoes/tipo/${idUser}`,
          { headers: header }
        );
        setTipoNotificacoes(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getTipoNotificacoes();
  }, []);

  useEffect(() => {
    const getAtuadores = async () => {
      try {
        var { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/atuadores?idSala=${idSala}`,
          {
            headers: header,
          }
        );
        setAtuadores(data);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAtuadores();
  }, []);

  useEffect(() => {
    const getAmostragens = async () => {
      try {
        var dataTotal;
        await axios
          .get(
            `${process.env.REACT_APP_URL_REQUEST}/amostragens?idSala=${idSala}`,
            {
              headers: header,
            }
          )
          .then((data: any) => {
            dataTotal = data.data;
            for (let key in classificacaoIAQ) {
              const numericKey = parseInt(key);
              if (dataTotal[0].iaq <= numericKey) {
                dataTotal[0].statusIAQ = classificacaoIAQ[key];
                break;
              }
            }
          });

        setAmostragens(dataTotal);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmostragens();
  }, []);

  useEffect(() => {
    const getMediasDiarias = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/mediaDiaria/10/${idSala}`,
          { headers: header }
        );

        setMediasDiarias(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getMediasDiarias();
  }, []);

  useEffect(() => {
    const getNotificacoes = async () => {
      try {
        var { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/notificacoes/${idUser}`,
          { headers: header }
        );

        /* filter by sala */
        data = data.filter((notificacao) => {
          return notificacao.sala == nomeSala;
        });

        data = data.slice(0, 4);

        setNotificacoes(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getNotificacoes();
  }, []);

  return isLoading !== true ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Dashboard</h1>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h3 className="header-text h3">{nomeSala}</h3>

          <Link style={{ color: "#486ADC" }} to={"/relatorio"}>
            Visualizar relatório
          </Link>
        </Grid>
        <Grid
          className="text-center"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <h5 className="text-center header-text valores pt-4">
                Classificação dos alertas
              </h5>
              <div>
                <PieChart
                  options={{
                    responsive: [
                      {
                        breakpoint: 480,
                        options: {
                          legend: {
                            position: "bottom",
                          },
                        },
                      },
                    ],
                    chart: {
                      type: "pie",
                    },
                    labels: tipoNotificacoes.map((notificacao) => {
                      return notificacao.aviso;
                    }),
                  }}
                  series={tipoNotificacoes.map((notificacao) => {
                    return Number(notificacao.recorrencia);
                  })}
                />
              </div>
            </GridBox>
          </Grid>
          {amostragens.map((amostragem) => (
            <Grid item xs={12} sm={12} md={3} key={amostragem.id}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <div className="pt-4">
                  <h5 className="text-center header-text valores">
                    Última atualização
                  </h5>
                  <h3 className="text-center header-text valores">
                    {amostragem.horaformatada}
                  </h3>
                  <h6 className="text-center salas">
                    {amostragem.dataformatada}
                  </h6>
                  <h6>
                    Temperatura:
                  </h6>
                  <h6
                    style={{
                      color: handleColors(
                        amostragem.temperatura,
                        "temperatura"
                      ),
                    }}
                  >
                    {amostragem.temperatura.toFixed(2)} ºC{" "}
                  </h6>
                  <h6>
                    Umidade:
                  </h6>
                  <h6
                    style={{
                      color: handleColors(amostragem.umidade, "umidade"),
                    }}
                  >
                    {amostragem.umidade.toFixed(2)} %{" "}
                  </h6>
                </div>
              </GridBox>
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={3}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <div style={{ paddingTop: "20px" }}>
                <h3 className="text-center header-text valores">
                  Score
                  <small>
                    <span
                      data-tooltip-id="tooltip-co2"
                      data-tooltip-content="O Score de qualidade do ar é um cálculo que leva em consideração o nível de dióxido de carbono do ambiente
                    e seus respectíveis níveis. Quanto maior o nível de CO2, o score aumenta exponencialmente. "
                    >
                      <AiIcons.AiOutlineQuestionCircle />
                    </span>
                  </small>
                </h3>
                <h1 className="text-center header-text mt-5 valores">
                  <WiStrongWind style={{ fontSize: 100 }} />
                  {amostragens.map((amostragem) => amostragem.iaq.toFixed(1))}
                  /500
                </h1>
                <h6 className="text-center salas">
                  Status:{" "}
                  {amostragens.map((amostragem) => amostragem.statusIAQ)}
                </h6>
              </div>
            </GridBox>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <h5 className="text-center header-text valores pt-4">
                Dióxido de Carbono
                <small>
                  <span
                    data-tooltip-id="tooltip-co2"
                    data-tooltip-content="O controle do nível de dióxido de carbono é essencial
                  para manter a qualidade do ar interno em ambientes fechados. Níveis elevados de
                   CO2 podem causar fadiga, dor de cabeça e outros sintomas relacionados à má ventilação.
                    Além disso, ambientes com alto teor de CO2 podem comprometer a produtividade,
                     a capacidade de concentração e a saúde dos ocupantes. "
                  >
                    <AiIcons.AiOutlineQuestionCircle />
                  </span>
                </small>
                <Tooltip className="mw-20" id="tooltip-co2" />
              </h5>
              <small>Média dos últimos 10 dias</small>

              <GraficoSalas
                options={{
                  chart: {
                    type: "line",
                  },
                  series: [
                    {
                      name: "CO2",
                      data: mediasDiarias.map((variacao) => {
                        return variacao.co2;
                      }),
                    },
                  ],
                  xaxis: {
                    categories: mediasDiarias.map((variacao) => {
                      return variacao.datamedicao;
                    }),
                  },
                }}
                formatAxis={true}
                height={230}
              />
              <Grid
                className="ml-2"
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Link to="/todosGraficos">
                    <h6 style={{ position: "absolute", color: "#486ADC" }}>
                      Ver variação diária
                    </h6>
                  </Link>
                </Grid>
              </Grid>
            </GridBox>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <h5 className="text-center header-text valores pt-4">
                Últimos alertas
              </h5>
              <div style={{ paddingTop: "10%" }}>
                <Carousel
                  items={notificacoes.map((notificacao) => {
                    return {
                      icon: icons[notificacao.aviso],
                      text: notificacao.aviso,
                      hora: notificacao.data,
                    };
                  })}
                />
              </div>
            </GridBox>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <h5 className="text-center header-text valores pt-4">
                Atuadores
              </h5>
              <div style={{ paddingTop: "10%" }}>
                {atuadores.map((atuador) => (
                  <label>
                    <h6 className="text-center">
                      {atuador.nome} <p></p>
                      <small>{atuador.ultima_atualizacao}</small>
                    </h6>

                    <Toggle
                      defaultChecked={atuador.ultimo_status}
                      checked={atuador.ultimo_status}
                      onChange={() => {
                        handleToggle(atuador);
                      }}
                    />
                    <span>
                      {atuador.ultimo_status ? "Ligado" : "Desligado"}
                    </span>
                    <Divider />
                  </label>
                ))}
              </div>
            </GridBox>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <h5 className="text-center header-text valores pt-4">
                Umidade e Temperatura
              </h5>
              <small>Média dos últimos 10 dias</small>
              <GraficoSalas
                options={{
                  chart: {
                    type: "line",
                  },
                  series: [
                    {
                      name: "Umidade",
                      data: mediasDiarias.map((variacao) => {
                        return variacao.umidade;
                      }),
                    },
                    {
                      name: "Temperatura",
                      data: mediasDiarias.map((variacao) => {
                        return variacao.temperatura;
                      }),
                    },
                  ],
                  xaxis: {
                    categories: mediasDiarias.map((variacao) => {
                      return variacao.datamedicao;
                    }),
                  },
                }}
                formatAxis={true}
                height={230}
              />
              <Grid
                className="ml-2"
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Link to="/todosGraficos">
                    <h6 style={{ position: "absolute", color: "#486ADC" }}>
                      Ver variação diária
                    </h6>
                  </Link>
                </Grid>
              </Grid>
            </GridBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GridBox
              sx={{
                width: "100%",
                height: "350px",
              }}
            >
              <h5 className="text-center header-text valores pt-4">
                Qualidade do ar
                <small>
                  <span
                    data-tooltip-id="tooltip-co2"
                    data-tooltip-content="O Score de qualidade do ar é um cálculo que leva em consideração o nível de dióxido de carbono do ambiente
                    e seus respectíveis níveis. Quanto maior o nível de CO2, o score aumenta exponencialmente. "
                  >
                    <AiIcons.AiOutlineQuestionCircle />
                  </span>
                </small>
              </h5>
              <GraficoBarras
                options={{
                  chart: {
                    type: "bar",
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                    },
                  },
                  series: [
                    {
                      name: "Score (QAI)",
                      data: mediasDiarias.map((variacao) => {
                        return variacao.iaq ? variacao.iaq.toFixed(2) : 0;
                      }),
                    },
                  ],
                  xaxis: {
                    categories: mediasDiarias.map((variacao) => {
                      return variacao.datamedicao;
                    }),
                  },
                }}
                formatAxis={true}
              />
            </GridBox>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default Ambientes;
