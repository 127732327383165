import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import "../../App.css";
import GridBox from "../../components/gridBox/gridBox";
import GraficoSalas from "../../components/graficoLinha/graficoSalas";
import { Amostragem } from "../../models/Amostragem";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import ReactGA from "react-ga4";

function TodosGraficos() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Visualização - Ambiente - Tempo Real" });
  }, []);
  const [amostragem, setAmostragem] = useState<Amostragem[]>([]);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [dataAtual, setDataAtual] = useState("");
  var [isLoading, setIsLoading] = React.useState(true);
  var [startDate, setStartDate] = useState<Date | null>(null);
  var [endDate, setEndDate] = useState<Date | null>(null);

  const handleLoading = () => {
    setIsLoading(false);
  };

  const handleDateChange = (date: Date | null, type: string) => {
    if (type === "start") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const idSala = sessionStorage.getItem("idSala");
  const nomeSala = sessionStorage.getItem("salaAtual");

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };
  useEffect(() => {
    const getAmostragens = async () => {
      try {
        let url = `${process.env.REACT_APP_URL_REQUEST}/mediaDia/${idSala}`;
        if (startDate && endDate) {
          /* cast startDate and endDate to and subtract 3 hours*/

          startDate = new Date(startDate);
          startDate.setTime(
            startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000
          );
          endDate = new Date(endDate);
          endDate.setTime(
            endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000
          );

          var dataInicial = startDate?.toISOString();
          var dataFinal = endDate?.toISOString();

          /* format dataInicial to dd-mm-yyyy */
          var diaInicial = dataInicial?.substr(8, 2);
          var mesInicial = dataInicial?.substr(5, 2);
          var anoInicial = dataInicial?.substr(0, 4);
          var horaInicial = dataInicial?.substr(11, 2);
          var minutoInicial = dataInicial?.substr(14, 2);
          var dataInicialFormatada = `${diaInicial}-${mesInicial}-${anoInicial}T${horaInicial}:${minutoInicial}:00`;

          /* format dataFinal to dd-mm-yyyy */
          var diaFinal = dataFinal?.substr(8, 2);
          var mesFinal = dataFinal?.substr(5, 2);
          var anoFinal = dataFinal?.substr(0, 4);
          var horaFinal = dataFinal?.substr(11, 2);
          var minutoFinal = dataFinal?.substr(14, 2);
          var dataFinalFormatada = `${diaFinal}-${mesFinal}-${anoFinal}T${horaFinal}:${minutoFinal}:00`;

          if (startDate) {
            url += `/${dataInicialFormatada}`;
            setDataAtual(dataInicialFormatada);
          }
          if (startDate && endDate) {
            url += `&${dataFinalFormatada}`;
            setDataAtual(dataInicialFormatada + " até " + dataFinalFormatada);
          }
        } else {
          var dataAtual = new Date();
          var diaAtual =
            dataAtual.getDate().toString().length === 1
              ? "0" + dataAtual.getDate()
              : dataAtual.getDate();
          var mesAtual =
            (dataAtual.getMonth() + 1).toString().length === 1
              ? "0" + (dataAtual.getMonth() + 1)
              : dataAtual.getMonth() + 1;
          var anoAtual = dataAtual.getFullYear();
          var dataAtualFormatada = `${diaAtual}-${mesAtual}-${anoAtual}T00:00:00`;
          var dataAtualFormatada2 = `${diaAtual}-${mesAtual}-${anoAtual}T23:59:00`;
          setDataAtual(dataAtualFormatada);
          url += `/${dataAtualFormatada}&${dataAtualFormatada2}`;
        }
        const { data } = await axios.get(url, { headers: header });

        setAmostragem(data);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmostragens();
  }, [startDate, endDate]);

  const generateColors = (data, aceitavel, atencao, ruim) => {
    let color = "#FF0000";
    return data.map((d, idx) => {
      console.log(d);
      if (d < aceitavel) {
        color = "#09c60d";
      } else if (d < atencao) {
        color = "#cdd82d";
      } else if (d < ruim){
        color = "#FFA500";
      } else {
        color = "#FF0000";
      }

      return {
        offset: (idx / data.length) * 100,
        color,
        opacity: 0.5,
      };
    });
  };

  return isLoading !== true ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7}>
            <h1 className="mt-5 header-text h1">Dashboard</h1>
            <h3 className="header-text h3">
              {nomeSala} <h6>Período: ({dataAtual.replaceAll("-", "/")})</h6>
            </h3>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <div className="mt-5 pl-5">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item>
                    <DateTimePicker
                      ampm={false}
                      label="Data inicial"
                      value={startDate}
                      onChange={(date) => handleDateChange(date, "start")}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item>
                    <DateTimePicker
                      ampm={false}
                      label="Data final"
                      value={endDate}
                      onChange={(date) => handleDateChange(date, "end")}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </div>
          </Grid>
        </Grid>

        <Grid
          className="text-center"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded1 ? "expanded-1" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - CO2
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded1(!isExpanded1)}
                >
                  {isExpanded1 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas
                  options={{
                    chart: {
                      type: "area",
                    },
                    series: [
                      {
                        name: "CO2",
                        data: amostragem.map((variacao) => {
                          return variacao.co2;
                        }),
                      },
                    ],
                    xaxis: {
                      categories: amostragem.map((variacao) => {
                        return variacao.datamedicao;
                      }),
                      tickInterval: 30 * 60 * 1000,
                      type: "datetime",
                    },
                    fill: {
                      type: "gradient",
                      gradient: {
                        shade: 'dark',
                        type: "horizontal",
                        shadeIntensity: 0.5,
                        gradientToColors: undefined,
                        opacityFrom: 0.1,
                        opacityTo: 1,
                        colorStops: generateColors(amostragem.map((variacao) => {
                          return variacao.co2;
                        }), 800, 1200, 1300),
                      },
                    },
                  }}
                  formatAxis={false}
                  type="line"
                />
              </GridBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded2 ? "expanded-2" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - Umidade
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded2(!isExpanded2)}
                >
                  {isExpanded2 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas
                  options={{
                    chart: {
                      type: "line",
                    },
                    series: [
                      {
                        name: "Umidade",
                        data: amostragem.map((variacao) => {
                          return variacao.umidade;
                        }),
                      },
                    ],
                    xaxis: {
                      categories: amostragem.map((variacao) => {
                        return variacao.datamedicao;
                      }),
                      tickInterval: 30 * 60 * 1000,
                      type: "datetime",
                    },
                    fill: {
                      type: "gradient",
                      gradient: {
                        shade: 'dark',
                        type: "horizontal",
                        shadeIntensity: 0.5,
                        gradientToColors: undefined,
                        opacityFrom: 0.1,
                        opacityTo: 1,
                        colorStops: generateColors(amostragem.map((variacao) => {
                          return variacao.umidade;
                        }), 60, 70, 80),
                      },
                    },
                  }}
                  formatAxis={false}
                  type="line"
                />
              </GridBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded3 ? "expanded-3" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - Temperatura
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded3(!isExpanded3)}
                >
                  {isExpanded3 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas
                  options={{
                    chart: {
                      type: "line",
                    },
                    series: [
                      {
                        name: "Temperatura",
                        data: amostragem.map((variacao) => {
                          return variacao.temperatura;
                        }),
                      },
                    ],
                    xaxis: {
                      categories: amostragem.map((variacao) => {
                        return variacao.datamedicao;
                      }),
                      tickInterval: 30 * 60 * 1000,
                      type: "datetime",
                    },
                    fill: {
                      type: "gradient",
                      gradient: {
                        shade: 'dark',
                        type: "horizontal",
                        shadeIntensity: 0.5,
                        gradientToColors: undefined,
                        opacityFrom: 0.1,
                        opacityTo: 1,
                        colorStops: generateColors(amostragem.map((variacao) => {
                          return variacao.temperatura;
                        }), 32, 34, 35),
                      },
                    },
                  }}
                  formatAxis={false}
                  type="line"
                />
              </GridBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded4 ? "expanded-4" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - Ruído
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded4(!isExpanded4)}
                >
                  {isExpanded4 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas
                  options={{
                    chart: {
                      type: "line",
                    },
                    series: [
                      {
                        name: "Ruido",
                        data: amostragem.map((variacao) => {
                          return variacao.db;
                        }),
                      },
                    ],
                    xaxis: {
                      categories: amostragem.map((variacao) => {
                        return variacao.datamedicao;
                      }),
                      tickInterval: 30 * 60 * 1000,
                      type: "datetime",
                    },
                  }}
                  formatAxis={false}
                />
              </GridBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded5 ? "expanded-5" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - TVOC
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded5(!isExpanded5)}
                >
                  {isExpanded5 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas
                  options={{
                    chart: {
                      type: "line",
                    },
                    series: [
                      {
                        name: "TVOC",
                        data: amostragem.map((variacao) => {
                          return variacao.tvoc;
                        }),
                      },
                    ],
                    xaxis: {
                      categories: amostragem.map((variacao) => {
                        return variacao.datamedicao;
                      }),
                      tickInterval: 30 * 60 * 1000,
                      type: "datetime",
                    },
                  }}
                  formatAxis={false}
                />
              </GridBox>
            </div>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded6 ? "expanded-6" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - Parametro 6
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded6(!isExpanded6)}
                >
                  {isExpanded6 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas />
              </GridBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded7 ? "expanded-7" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - Parametro 7
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded7(!isExpanded7)}
                >
                  {isExpanded7 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas />
              </GridBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded8 ? "expanded-8" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - Parametro 8
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded8(!isExpanded8)}
                >
                  {isExpanded8 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas />
              </GridBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={`grid-box ${isExpanded9 ? "expanded-9" : ""}`}>
              <GridBox
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <h5 className="text-center header-text valores pt-2">
                  Variação - Parametro 9
                </h5>
                <div
                  style={{ color: "#486ADC" }}
                  onClick={() => setIsExpanded9(!isExpanded9)}
                >
                  {isExpanded9 ? "Minimizar" : "Expandir"}
                </div>
                <GraficoSalas />
              </GridBox>
            </div>
          </Grid> */}
        </Grid>
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default TodosGraficos;
