import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import GridBox from "../../components/gridBox/gridBox";
import "../../App.css";
import MapWithMarkers from "../../components/mapa/mapaQualidadeDoAr";
import ReactGA from "react-ga4";

function VisaoMundo() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Gráfico Qualidade do Ar" });
  }, []);
  var [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return !isLoading ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Dashboard</h1>
        <h3 className="header-text h3">Mapa da qualidade do ar</h3>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12}>
            <GridBox
              sx={{
                height: "80%",
                width: "100%",
              }}
            >
              <MapWithMarkers />
            </GridBox>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default VisaoMundo;
