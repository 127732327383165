import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import '../../../App.css';
import axios from "axios";
import TabelaHome from "../../../components/tabela/tabelaHome";
import CircularProgress from "@mui/material/CircularProgress";
import DispositivoForm from "../../../components/forms/dispositivo";
import { Dispositivo } from "../../../models/Dispositivo";
import NotificacaoForm from "../../../components/forms/notificacao";

function ConfiguracaoNotificacao() {
  const [dispositivos, setDispositivos] = useState<Dispositivo[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };
  const idUser = sessionStorage.getItem("sessionNumber");

  useEffect(() => {
    const getAmbientes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/dispositivo?idUsuario=${idUser}`,
          { headers: header }
        );
        setDispositivos(data);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmbientes();
  }, []);

  return !isLoading ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Configurações</h1>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h3 className="header-text h3">Notificações</h3>
        </Grid>

        <NotificacaoForm />
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default ConfiguracaoNotificacao;