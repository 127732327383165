import React, { useContext } from "react";
import { Table } from "antd";
import { ThemeContext } from "styled-components";
import './tabelaHome.css'

const TabelaHome = (props) => {

  const { title, colors_components } = useContext(ThemeContext);

  // Essa função define o nome da classe que deverá ser usada do CSS de acordo com o title do tema atual
  // (Isso não usa as colors_components então para alterações o css deve ser mudado diretamente)
  const setTheme = (title: string): string => {
    if (title === 'light') {
      return "table-styled-class-light";
    } else {
      return "table-styled-class-dark";
    }
  }

  return (
    <div
      style={{
        backgroundColor: colors_components.background,
        color: colors_components.text,
        height: props.height ? props.height : "300px",
        width: props.width ? props.width : "80%",
        overflowX: "auto",
        borderRadius: "18px",
      }}
    >
      <Table
        dataSource={props.dataSource}
        columns={props.columns}
        rowClassName={setTheme(title)}
        style={{
          textAlign: "center",
        }}
      />

    </div>
  );
};

export default TabelaHome;
