import React, { Fragment, useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Ambiente } from "../../models/Ambiente";
import CircularProgress from "@mui/material/CircularProgress";
import GridBox from "../../components/gridBox/gridBox";
import { Link } from "react-router-dom";
import { WiThermometer, WiRaindrop, WiStrongWind } from "react-icons/wi";
import axios from "axios";
import Button from "@mui/material/Button";
//import "../../App.css";
import GraficoSalas from "../../components/graficoLinha/graficoSalas";
import TabelaHome from "../../components/tabela/tabelaHome";
import { Notificacao } from "../../models/Notificacoes";
import * as AiIcons from "react-icons/ai";
import { Amostragem } from "../../models/Amostragem";
import ReactGA from "react-ga4";

import { ThemeContext } from "styled-components";

const parametros = ["CO2", "Umidade", "Temperatura"];
const numeroRandomico = Math.floor(Math.random() * parametros.length);
const parametroAtual = parametros[numeroRandomico];

function Home() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  const { colors, colors_components, colors_indicators } = useContext(ThemeContext);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Página inicial",
    });
  }, []);
  var [reload, setReload] = useState(0);
  const [ambientes, setAmbientes] = useState<Ambiente[]>([]);
  const [variacaoMediaDiaria, setVariacaoMediaDiaria] = useState<
    Amostragem[][]
  >([]);
  const [eixoX, setEixoX] = useState<Amostragem[]>([]);
  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const nameUser = sessionStorage.getItem("nameUser");
  const idUser = sessionStorage.getItem("sessionNumber");

  function handleColors(valor, parametro) {
    if (parametro === "temperatura") {
      if (valor > 35) {
        return "#FF0000";
      } else if (valor > 33) {
        return "#BBBB00";
      } else {
        return "#128D15";
      }
    } else if (parametro === "umidade") {
      if (valor > 70) {
        return "#FF0000";
      } else if (valor > 40) {
        return "#BBBB00";
      } else {
        return "#128D15";
      }
    } else {
      if (valor > 100) {
        return "#FF0000";
      } else if (valor > 50) {
        return "#BBBB00";
      } else {
        return "#00FF00";
      }
    }
  }

  const date = new Date();
  const hour = date.getHours();
  const greeting =
    hour >= 12 && hour < 18
      ? "Boa tarde, "
      : hour >= 18
        ? "Boa noite, "
        : "Bom dia, ";

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };

  const formatAmbienteData = (ambiente) => {
    const nome = ambiente.sala;
    const dtformatada = ambiente.dtformatada.substring(11, 16);
    return { ...ambiente, sala: nome, dtformatada };
  };

  useEffect(() => {
    const getAmbientes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/ambientes/actions/${idUser}`,
          { headers: header }
        );
        const formattedData = data.slice(0, 4).map(formatAmbienteData);
        setAmbientes(formattedData);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmbientes();
  }, [reload]);

  useEffect(() => {
    const getVariacao = async () => {
      try {
        /* takes 4 random indexes in ambientes and get their identificacao */
        var randomIndexes: number[] = [];
        for (var i = 0; i < 4; i++) {
          const identificacoes = ambientes.map(
            (ambiente) => ambiente.identificacao
          );
          const randomIndex =
            identificacoes[Math.floor(Math.random() * identificacoes.length)];
          if (!randomIndexes.includes(randomIndex)) {
            randomIndexes.push(randomIndex);
          }
        }

        const randomIndexesString = randomIndexes.join(",");

        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/mediaDiaria/10/${parametroAtual}/${randomIndexesString}`,
          { headers: header }
        );

        /* group by ambientes.id */
        const groupedData = data.reduce((acc, item) => {
          const key = item.id;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});

        setEixoX(
          data.filter((_, i) => i % Object.keys(groupedData).length === 0)
        );

        setVariacaoMediaDiaria(groupedData);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getVariacao();
  }, [ambientes]);

  useEffect(() => {
    const getNotificacoes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/notificacoes/${idUser}`,
          { headers: header }
        );
        setNotificacoes(data);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getNotificacoes();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setReload((reload += 1));
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: "Página inicial - Refresh automático",
      });
    }, 180000);
    return () => clearInterval(timer);
  }, []);

  return !isLoading ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 pt-2  h1">Dashboard</h1>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <h3 className="h3">Banheiros</h3>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {ambientes.map((ambiente) => (
                <Grid item xs={12} sm={12} md={3} key={ambiente.identificacao}>

                  <Link
                    to={"/vapeDetect/ambiente/view?id=" + ambiente.identificacao}
                    onClick={() => {
                      sessionStorage.setItem("salaAtual", ambiente.sala);
                      sessionStorage.setItem("sala", JSON.stringify(ambiente));
                      sessionStorage.setItem(
                        "idSala",
                        String(ambiente.identificacao)
                      );
                    }}
                  >
                    <GridBox
                      className="grid-box-home"
                      sx={{ // ------------------ background dos ambientes da home está aqui vvv
                        background:
                          "linear-gradient(to bottom, " +
                          colors_components.background +
                          " 95%, " +
                          (ambiente.status === "Online" ? colors_indicators.regular : '#636363') +
                          " 10px)",
                      }}
                    >
                      <div>
                        <h5 className="text-center header-text mt-4 valores">
                          {ambiente.sala}
                        </h5>
                        <h5 className="text-center mt-1 pt-1 salas">
                          atualizado às {ambiente.dtformatada} {(ambiente.status === "Online" ? '' : '(offline)')}
                        </h5>

                      </div>
                    </GridBox>
                  </Link>

                </Grid>
              ))}


            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Link to="/vapeDetect/todosAmbientes">
                <h6
                  className="mt-5 pt-2"
                  style={{ right: "11%", color: colors.contrast }}
                >
                  Ver tudo
                </h6>
              </Link>
            </Grid>

          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <div>
              <h3 className="h3">Alertas</h3>
              <TabelaHome
                width="100%"
                height="700px"
                columns={[
                  {
                    title: "Data",
                    dataIndex: "data",
                    key: "data",
                  },
                  {
                    title: "Sala",
                    dataIndex: "sala",
                    key: "sala",
                  },
                  {
                    title: "Aviso",
                    dataIndex: "aviso",
                    key: "aviso",
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                  },
                ]}
                dataSource={notificacoes.map((notificacao) => {
                  return {
                    key: notificacao.id,
                    data: notificacao.data,
                    sala: notificacao.sala,
                    aviso: notificacao.aviso,
                    status: (
                      <AiIcons.AiTwotoneCiCircle color={notificacao.status} />
                    ),
                  };
                })}
              />
            </div>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Link to="/notificacoes">
                <h6
                  className="pt-1"
                  style={{
                    right: "11%",
                    position: "absolute",
                    color: colors.contrast,
                  }}
                >
                  Ver tudo{" "}
                </h6>
              </Link>
            </Grid>
          </Grid> */}
        </Grid>
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default Home;
