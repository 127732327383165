import React, { useEffect } from "react";
import LoginModal from "../../components/loginModal/loginModal";
//import "../../App.css";
import ReactGA from "react-ga4";

function Login() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Página de Login" });
  }, []);
  return (
    <>
      <div>
        <LoginModal />
      </div>
    </>
  );
}

export default Login;
