import React, { Fragment, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import {
  BiDevices,
  BiBell,
  BiDetail,
  BiUser,
  BiWifi,
  BiHome,
  BiTrendingDown,
} from "react-icons/bi";
import GridBox from "../../components/gridBox/gridBox";
import "../../App.css";
import ReactGA from "react-ga4";

import { ThemeContext } from "styled-components";

function Configuracoes() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  const { colors, colors_components } = useContext(ThemeContext);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Configuração" });
  }, []);
  return (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Dashboard</h1>
        <h3 className="header-text h3">Configurações</h3>
        <Grid
          className="text-center"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Link to="/configuracao/dispositivos">
              <GridBox
                sx={{
                  width: "100%",
                  height: "300px",
                  //background: "#F4F8FB",
                }}
              >
                <BiDevices
                  style={{
                    fontSize: "100px",
                    color: colors.contrast,
                    marginTop: "15%",
                  }}
                />
                <h3 className="h3" style={{ color: colors.contrast }}>
                  Dispositivos
                </h3>
              </GridBox>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Link to="/configuracao/ambientes">
              <GridBox
                sx={{
                  width: "100%",
                  height: "300px",
                  //background: "#F4F8FB",
                }}
              >
                <BiHome
                  style={{
                    fontSize: "100px",
                    color: colors.contrast,
                    marginTop: "15%",
                  }}
                />
                <h3 className="h3" style={{ color: colors.contrast }}>
                  Ambientes
                </h3>
              </GridBox>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Link to="/configuracao/notificacao">
              <GridBox
                sx={{
                  width: "100%",
                  height: "300px",
                  //background: "#F4F8FB",
                }}
              >
                <BiBell
                  style={{
                    fontSize: "100px",
                    color: colors.contrast,
                    marginTop: "15%",
                  }}
                />
                <h3 className="h3" style={{ color: colors.contrast }}>
                  Notificações
                </h3>
              </GridBox>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <GridBox
              sx={{
                width: "100%",
                height: "300px",
                background: colors_components.unavailable,
              }}
            >
              <BiDetail
                style={{
                  fontSize: "100px",
                  color: colors.contrast,
                  marginTop: "15%",
                }}
              />
              <h3 className="h3" style={{ color: colors.contrast }}>
                Relatórios
              </h3>
            </GridBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Link to="/configuracao/regra">
              <GridBox
                sx={{
                  width: "100%",
                  height: "300px",
                  //background: "#F4F8FB",
                }}
              >
                <BiTrendingDown
                  style={{
                    fontSize: "100px",
                    color: colors.contrast,
                    marginTop: "15%",
                  }}
                />
                <h3 className="h3" style={{ color: colors.contrast }}>
                  Regras
                </h3>
              </GridBox>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
          <Link to="/configuracao/atuador">
            <GridBox
              sx={{
                width: "100%",
                height: "300px",
                //background: "#F4F8FB",
              }}
            >
              <BiWifi
                style={{
                  fontSize: "100px",
                  color: colors.contrast,
                  marginTop: "15%",
                }}
              />
              <h3 className="h3" style={{ color: colors.contrast }}>
                Atuadores
              </h3>
            </GridBox>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
}

export default Configuracoes;
