import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Ambiente } from "../../models/Ambiente";
import CircularProgress from "@mui/material/CircularProgress";
import GridBox from "../../components/gridBox/gridBox";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../App.css";
import GraficoSalas from "../../components/graficoLinha/graficoSalas";
import TabelaHome from "../../components/tabela/tabelaHome";
import ReactGA from "react-ga4";

function TodosAmbientes() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Todos os Ambientes" });
  }, []);
  const [ambientes, setAmbientes] = useState<Ambiente[]>([]);
  var [isLoading, setIsLoading] = React.useState(true);
//  setTimeout(() => window.location.reload(), 120000);

  const handleLoading = () => {
    setIsLoading(false);
  };

  var nameUser = sessionStorage.getItem("nameUser");
  var idUser = sessionStorage.getItem("sessionNumber");

  var date = new Date();
  var hour = date.getHours();
  if (hour >= 12 && hour < 18) {
    nameUser = "Boa tarde, " + nameUser;
  } else if (hour >= 18) {
    nameUser = "Boa noite, " + nameUser;
  } else {
    nameUser = "Bom dia, " + nameUser;
  }

  useEffect(() => {
    var header = {
      sessionToken: sessionStorage.getItem("sessionToken"),
    };

    const getAmbientes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/ambientes/actions/${idUser}`,
          {
            headers: header,
          }
        );
        data.forEach((element) => {
          if (element.sala.length > 9) {
            var nome = element.sala.split(" ");
            var nomeFinal = "";
            nome.forEach((element) => {
              nomeFinal += element.substring(0, 3) + ". ";
            });
            element.sala = nomeFinal;
          }

          element.dtformatada = element.dtformatada.substring(11, 16);
        });

        setAmbientes(data);
        handleLoading();
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getAmbientes();
  }, []);

  return !isLoading ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Dashboard</h1>
        <h3 className="header-text h3">{nameUser}.</h3>
        <Grid container rowSpacing={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {ambientes.map((ambiente) => (
            <Grid item xs={12} sm={12} md={3} key={ambiente.identificacao}>
              <Link
                to={"/ambientes/view?id=" + ambiente.identificacao}
                onClick={() => {
                  sessionStorage.setItem("salaAtual", ambiente.sala);
                }}
              >
                <GridBox
                  sx={{
                    background:
                      "linear-gradient(to bottom, " +
                      "#F4F8FB" +
                      " 95%, " +
                      (ambiente.status === "Online" ? "green" : "red") +
                      " 10px)",
                  }}
                >
                  <div>
                    <h5 className="text-center mt-5 pt-3 salas">
                      {ambiente.sala} - {ambiente.dtformatada}
                    </h5>
                    <h3 className="text-center header-text mt-5 valores">
                      {ambiente.temperatura.toFixed(2)} ºC
                    </h3>
                    <h5 className="text-center header-text valores">
                      {ambiente.umidade.toFixed(2)} %
                    </h5>
                  </div>
                </GridBox>
              </Link>
            </Grid>
          ))}
        </Grid>
        
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default TodosAmbientes;
