import React, { Fragment, useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Ambiente } from "../../models/Ambiente";
import Fade from "@mui/material/Fade";
import axios from "axios";
import GridBox from "../gridBox/gridBox";
import Dropdown from "react-bootstrap/Dropdown";
import RadioButton from "antd/es/radio/radioButton";
import { Notificacao } from "../../models/Notificacoes";
import { Usuario } from "../../models/Usuario";
import { ThemeContext } from "styled-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Inter",
    padding: "2em",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2em",
    marginBottom: "2em",
  },
  textField: {
    marginBottom: "1em",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "18px",
  },
  header: {
    margin: "2em 0",
    fontWeight: "bold",
    color: "#333",
  },
  button: {
    width: "300px",
    height: "50px",
    borderRadius: "18px",
    color: "#fff",
    fontWeight: "bold",
    marginTop: "25px!Important",
  },
  logo: {
    width: "200px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    margin: "2em 0",
  },
});

const NotificacaoForm = () => {
  const [usuario, setUsuario] = useState<Usuario>(new Usuario());
  const [notificacaoEmail, setNotificacaoEmail] = useState(false);
  const [notificacaoWhatsapp, setNotificacaoWhatsapp] = useState(false);
  const [notificacaoTelegram, setNotificacaoTelegram] = useState(false);
  const [loading, setLoading] = useState(false);

  const { colors_components } = useContext(ThemeContext);

  const handleCheckboxChangeEmail = (event) => {
    setNotificacaoEmail(event.target.checked);
  };

  const handleCheckboxChangeWhatsapp = (event) => {
    setNotificacaoWhatsapp(event.target.checked);
  };

  const handleCheckboxChangeTelegram = (event) => {
    setNotificacaoTelegram(event.target.checked);
  };

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };

  const handleUpdateRequest = async (usuario, method) => {
    if (method === "PUT") {
      console.log(usuario);
      axios
        .put(`${process.env.REACT_APP_URL_REQUEST}/usuario/update`, {
          headers: header,
          body: usuario,
        })
        .then((res) => {
          window.location.reload();
        });
    }
  };

  useEffect(() => {
    const getUsuario = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_URL_REQUEST}/usuario/${sessionStorage.getItem(
              "sessionNumber"
            )}`,
            {
              headers: header,
            }
          )
          .then((data: any) => {
            setUsuario(data.data);
            setNotificacaoEmail(data.data[0].notificacao_email);
            setNotificacaoWhatsapp(data.data[0].notificacao_whatsapp);
            setNotificacaoTelegram(data.data[0].notificacao_telegram);
          });
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getUsuario();
  }, []);

  const createFormObject = (
    notificacaoEmail,
    notificacaoWhatsapp,
    notificacaoTelegram
  ) => {
    return {
      id: sessionStorage.getItem("sessionNumber"),
      notificacao_email: notificacaoEmail,
      notificacao_whatsapp: notificacaoWhatsapp,
      notificacao_telegram: notificacaoTelegram,
    };
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const usuario = createFormObject(
      notificacaoEmail,
      notificacaoWhatsapp,
      notificacaoTelegram
    );
    handleUpdateRequest(usuario, "PUT");
  };

  const classes = useStyles();

  return (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <div className={classes.root}>
        <GridBox
          sx={{
            width: "400px",
            height: "500px",
            background: colors_components.background,
            color: colors_components.text,
          }}
        >
          <h2 className={classes.form}>
            {" "}
            Notificações
          </h2>
          <form className={classes.form} onSubmit={onSubmitForm}>
            <h6
              style={{
                textAlign: "center",
                width: "300px",
              }}
            >
              Notificações via WhatsApp:
            </h6>
            <label>
              <input
                type="checkbox"
                checked={notificacaoWhatsapp}
                onChange={handleCheckboxChangeWhatsapp}
              />
              {notificacaoWhatsapp ? " Habilitado" : " Desabilitado"}
            </label>
            <h6
              style={{
                textAlign: "center",
                width: "300px",
              }}
            >
              Notificações via Telegram:
            </h6>
            <label>
              <input
                type="checkbox"
                checked={notificacaoTelegram}
                onChange={handleCheckboxChangeTelegram}
              />
              {notificacaoTelegram ? " Habilitado" : " Desabilitado"}
            </label>
            <h6
              style={{
                textAlign: "center",
                width: "300px",
              }}
            >
              Notificações via Email:
            </h6>
            <label>
              <input
                type="checkbox"
                checked={notificacaoEmail}
                onChange={handleCheckboxChangeEmail}
              />
              {notificacaoEmail ? " Habilitado" : " Desabilitado"}
            </label>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={loading}
            >
              Atualizar
            </Button>
            <Fade in={loading}>
              <CircularProgress />
            </Fade>
          </form>
        </GridBox>
      </div>
    </Fragment>
  );
};

export default NotificacaoForm;
