import React, { useState } from "react";
import ApexCharts from "react-apexcharts";

const PieChart = (props) => {
  
  return (
    <div className="pie-chart">
      <ApexCharts options={props.options} series={props.series} type="pie" responsive={"true"} />
    </div>
  );
};

export default PieChart;
