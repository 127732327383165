import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "../../App.css";
import TabelaHome from "../../components/tabela/tabelaHome";
import { Notificacao } from "../../models/Notificacoes";
import * as AiIcons from "react-icons/ai";
import ReactGA from "react-ga4";

function Notificacoes() {
  ReactGA.initialize("G-J2RWZ9FWRN");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Notificações" });
  }, []);

  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const idUser = sessionStorage.getItem("sessionNumber");

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };

  useEffect(() => {
    const getNotificacoes = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/notificacoes/${idUser}`,
          { headers: header }
        );
        setNotificacoes(data);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getNotificacoes();
  }, []);

  return !isLoading ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Dashboard</h1>
        <h3 className="header-text h3">Notificações</h3>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12}>
          <TabelaHome height="100%" width="100%"
                /* creates a map of notificacoes */
                columns={[
                  {
                    title: "Data",
                    dataIndex: "data",
                    key: "data",
                  },
                  {
                    title: "Sala",
                    dataIndex: "sala",
                    key: "sala",
                  },
                  {
                    title: "Aviso",
                    dataIndex: "aviso",
                    key: "aviso",
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                  },
                ]}
                dataSource={
                  notificacoes.map((notificacao) => {
                    return {
                      key: notificacao.id,
                      data: notificacao.data,
                      sala: notificacao.sala,
                      aviso: notificacao.aviso,
                      status: <AiIcons.AiTwotoneCiCircle color={notificacao.status} />,
                    };
                  })
                }
              />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default Notificacoes;
