import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shouldPureComponentUpdate from 'react-pure-render/function';

const K_WIDTH = 40;
const K_HEIGHT = 40;

export default class MyGreatPlace extends Component {
  static propTypes = {
    text: PropTypes.string,
    color: PropTypes.string
  };

  static defaultProps = {};

  shouldComponentUpdate = shouldPureComponentUpdate;

  render() {
    return (
      <div style={{
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,
        borderRadius: K_HEIGHT,
        backgroundColor: 'white',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4,
        border: `5px solid ${this.props.color}`
      }}>
        {this.props.text}
      </div>
    );
  }
}