import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import "../../../App.css";
import axios from "axios";
import TabelaHome from "../../../components/tabela/tabelaHome";
import CircularProgress from "@mui/material/CircularProgress";
import DispositivoForm from "../../../components/forms/dispositivo";
import { Dispositivo } from "../../../models/Dispositivo";
import NotificacaoForm from "../../../components/forms/notificacao";
import RegraForm from "../../../components/forms/regra";
import { Regra } from "../../../models/Regra";

function ConfiguracaoRegra() {
  const [regras, setRegras] = useState<Regra[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };
  const idUser = sessionStorage.getItem("sessionNumber");

  useEffect(() => {
    const getRegras = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_REQUEST}/regra?action=${idUser}`,
          { headers: header }
        );
        setRegras(data);
        setIsLoading(false);
      } catch (err) {
        console.error(`Unexpected error: ${err}`);
      }
    };

    getRegras();
  }, []);

  return !isLoading ? (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap"
        rel="stylesheet"
      />
      <div className="container">
        <h1 className="mt-5 header-text h1">Configurações</h1>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h3 className="header-text h3">Regras</h3>
          <Link
            to={"#"}
            onClick={() => {
              setShowForm(!showForm);
              sessionStorage.setItem("regraEdicao", "{}");
            }}
            style={{ color: "#486ADC" }}
          >
            Cadastrar regra
          </Link>
        </Grid>
        {showForm && (
          <div className="expanded-form">
            <RegraForm />
          </div>
        )}
        <Grid
          className="text-center"
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <TabelaHome
            height="300%"
            width="100%"
            columns={[
              {
                title: "Parametro",
                dataIndex: "parametro",
                key: "parametro",
              },
              {
                title: "Maximo",
                dataIndex: "maximo",
                key: "maximo",
              },
              {
                title: "Minimo",
                dataIndex: "minimo",
                key: "minimo",
              },
              {
                title: "Tolerância",
                dataIndex: "tolerancia",
                key: "tolerancia",
              },
              {
                title: "Sala",
                dataIndex: "sala",
                key: "sala",
              },
              {
                title: "Prédio",
                dataIndex: "predio",
                key: "predio",
              },
              {
                title: "Editar",
                dataIndex: "editar",
                key: "editar",
              },
              {
                title: "Excluir",
                dataIndex: "excluir",
                key: "excluir",
              },
            ]}
            dataSource={regras.map((regras) => {
              return {
                key: regras.parametro,
                parametro: regras.parametro,
                minimo: regras.minimo,
                maximo: regras.maximo,
                tolerancia: regras.tolerancia,
                sala: regras.sala,
                predio: regras.predio,
                editar: (
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      sessionStorage.setItem(
                        "regraEdicao",
                        JSON.stringify(regras)
                      );
                      setShowForm(true);
                    }}
                  >
                    Editar
                  </button>
                ),
                excluir: (
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      /* ask if user is sure  */
                      if (window.confirm("Tem certeza que deseja excluir?")) {
                        console.log(regras.id);
                        axios
                          .delete(
                            `${process.env.REACT_APP_URL_REQUEST}/regra?action=${regras.id}`,
                            { headers: header }
                          )
                          .then((res) => {
                            window.location.reload();
                          });
                      }
                    }}
                  >
                    Excluir
                  </button>
                ),
              };
            })}
          />
        </Grid>
      </div>
    </Fragment>
  ) : (
    <div className="container">
      <h1 className="text-center mt-5 header-text">
        <CircularProgress />
      </h1>
    </div>
  );
}

export default ConfiguracaoRegra;
