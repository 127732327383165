import React, { Fragment, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import logo from "../../public/floats_black.png";
import GridBox from "../gridBox/gridBox";

import { ThemeContext } from "styled-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Inter",
    padding: "2em",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2em",
    marginBottom: "2em",
  },
  textField: {
    margin: "1em 0",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "18px",
    marginTop: "25px!Important",
  },
  header: {
    margin: "2em 0",
    fontWeight: "bold",
    color: "#333",
  },
  button: {
    width: "300px",
    height: "50px",
    borderRadius: "18px",
    color: "#fff",
    fontWeight: "bold",
    marginTop: "25px!Important",
  },
  logo: {
    width: "200px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    margin: "2em 0",
  },
});

const LoginModal = () => {
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");

  const { colors, colors_components } = useContext(ThemeContext);


  const createLoginObject = (username, password) => {
    var crypto = require("crypto");
    return {
      usr: username,
      pass: crypto.createHash("md5").update(password).digest("hex"),
    };
  };

  const handleLoginRequest = async (loginObject) => {
    try {
      const response = await fetch(`${process.env["REACT_APP_URL_REQUEST"]}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify(loginObject),
      });

      let json = await response.json();
      if (response.status === 200) {
        sessionStorage.setItem("sessionNumber", json.id);
        sessionStorage.setItem("sessionToken", json.session_token);
        sessionStorage.setItem("userStatus", "loggedIn");
        sessionStorage.setItem("nameUser", json.nome);
        sessionStorage.setItem("typeUser", json.chat_id);
        if (sessionStorage.getItem("typeUser") == "smartAir"){
          window.location.href = "/home";
        } else {
          window.location.href = "/vapeDetect/home";
        }
      } else {
        setLoading(false);

        /* creates json object with json.message*/
        var resposta = JSON.parse(json.message);
        alert(resposta.result);
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      } else {
        console.log("Unexpected error", err);
      }
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loginObject = createLoginObject(usuario, senha);
    handleLoginRequest(loginObject);
  };

  const classes = useStyles();

  return (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <div className={classes.root}>
        <h1 className={classes.header}>{"  "}</h1>
        <GridBox
          sx={{
            width: "400px",
            height: "500px",
            background: colors_components.background,
            color: colors_components.text,
          }}
        >
          <img src={logo} alt="Company Logo" className={classes.logo} />
          <form className={classes.form} onSubmit={onSubmitForm}>
            <TextField
              label="Usuário"
              className={classes.textField}
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              required
            />
            <TextField
              label="Senha"
              className={classes.textField}
              type="password"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              required
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={loading}
            >
              Entrar
            </Button>
            
            <div className="mt-5" style={{ color: colors.contrast }}>
              Esqueci minha senha
            </div>
            <Fade in={loading}>
              <CircularProgress />
            </Fade>
          </form>
        </GridBox>
      </div>
    </Fragment>
  );
};

export default LoginModal;
