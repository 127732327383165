import React, { Fragment, useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Ambiente } from "../../models/Ambiente";
import Fade from "@mui/material/Fade";
import axios from "axios";
import GridBox from "../gridBox/gridBox";
import Dropdown from "react-bootstrap/Dropdown";
import RadioButton from "antd/es/radio/radioButton";
import { ThemeContext } from "styled-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Inter",
    padding: "2em",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2em",
    marginBottom: "2em",
  },
  textField: {
    marginBottom: "1em",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "18px",
  },
  header: {
    margin: "2em 0",
    fontWeight: "bold",
    color: "#333",
  },
  button: {
    width: "300px",
    height: "50px",
    borderRadius: "18px",
    color: "#fff",
    fontWeight: "bold",
    marginTop: "25px!Important",
  },
  logo: {
    width: "200px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    margin: "2em 0",
  },
});

const AmbienteForm = () => {
  const [ambientes, setAmbientes] = useState<Ambiente[]>([]);
  const [loading, setLoading] = useState(false);
  const [identificacao, setIdentificacao] = useState(0);
  const [nome, setNome] = useState("");
  const [dimensao, setDimensao] = useState("");
  const [capmaxima, setCapmaxima] = useState(0);
  const [predio, setPredio] = useState("");
  const [arquivo, setArquivo] = useState("");
  const [notificacaoHabilitada, setNotificacaoHabilitada] = useState(true);

  const { colors_components } = useContext(ThemeContext);

  const handleCheckboxChange = (event) => {
    setNotificacaoHabilitada(event.target.checked);
  };

  const header = {
    sessionToken: sessionStorage.getItem("sessionToken"),
  };

  const handleEditClick = (ambient: Ambiente) => {
    setIdentificacao(ambient.identificacao);
    setNome(ambient.sala);
    setDimensao(ambient.dimensao);
    setCapmaxima(ambient.capmaxima);
    setPredio(ambient.predio);
    setNotificacaoHabilitada(ambient.notificacaoHabilitada);
    setArquivo(ambient.foto);
  };

  const createFormObject = (
    identificacao,
    nome,
    dimensao,
    capmaxima,
    predio,
    notificacaoHabilitada,
    arquivo
  ) => {
    return {
      nome: nome,
      dimensao: dimensao,
      capmaxima: capmaxima,
      predio: predio,
      idUsuario: sessionStorage.getItem("sessionNumber"),
      idAmbiente: identificacao,
      notificacao_habilitada: notificacaoHabilitada,
      foto: arquivo,
    };
  };

  const handleLoginRequest = async (ambiente, method) => {
    if (method === "PUT") {
      axios
        .put(`${process.env.REACT_APP_URL_REQUEST}/ambientes/actions/put`, {
          headers: header,
          body: ambiente,
        })
        .then((res) => {
          window.location.reload();
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_URL_REQUEST}/ambientes/actions/insert`, {
          headers: header,
          body: ambiente,
        })
        .then((res) => {
          /* close box and reload the page */
          window.location.reload();
        });
    }
  };

  /* verify if session contains an ambiente. If so, replace in the texts fields to allow update */
  useEffect(() => {
    if (sessionStorage.getItem("ambienteEdicao") !== "{}") {
      var objeto = sessionStorage.getItem("ambienteEdicao");
      let ambiente = JSON.parse(objeto ? objeto : "{}");
      setIdentificacao(ambiente.identificacao);
      setNome(ambiente.sala);
      setDimensao(ambiente.dimensao);
      setCapmaxima(ambiente.capmaxima);
      setPredio(ambiente.predio);
      setNotificacaoHabilitada(ambiente.notificacao_habilitada);
      setArquivo(ambiente.foto);
    }
  }, []);

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const ambiente = createFormObject(
      identificacao,
      nome,
      dimensao,
      capmaxima,
      predio,
      notificacaoHabilitada,
      arquivo
    );
    if (sessionStorage.getItem("ambienteEdicao") !== "{}") {
      handleLoginRequest(ambiente, "PUT");
    } else {
      handleLoginRequest(ambiente, "POST");
    }
  };

  const classes = useStyles();

  return (
    <Fragment>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap"
        rel="stylesheet"
      />
      <div className={classes.root}>
        <GridBox
          sx={{
            width: "400px",
            height: "900px",
            background: colors_components.background,
            color: colors_components.text,
          }}
        >
          <h2 className={classes.form}>
            {" "}
            {sessionStorage.getItem("ambienteEdicao") !== "{}"
              ? "Editar Ambiente"
              : "Cadastrar Ambiente"}
          </h2>
          <form className={classes.form} onSubmit={onSubmitForm}>
            <h6
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Nome (obrigatório):
            </h6>
            <TextField
              label="Nome"
              className={classes.textField}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required
            />
            <h6
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Dimensões:
            </h6>
            <TextField
              label="Dimensões (xmm x ymm x zmm)"
              className={classes.textField}
              value={dimensao}
              onChange={(e) => setDimensao(e.target.value)}
              required
            />
            <h6
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Capacidade máxima:
            </h6>
            <TextField
              label="Capacidade máxima de pessoas"
              className={classes.textField}
              value={capmaxima}
              onChange={(e) => setCapmaxima(Number(e.target.value))}
              required
            />

            <h6
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Predio:
            </h6>
            <TextField
              label="Prédio"
              className={classes.textField}
              value={predio}
              onChange={(e) => setPredio(e.target.value)}
              required
            />
            <h6
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Faça o upload de uma imagem:
            </h6>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files ? e.target.files[0] : null;
                const reader = new FileReader();
                reader.readAsDataURL(file!);
                reader.onload = () => {
                  const image = new Image();
                  image.src = reader.result as string;
                  image.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d")!;
                    canvas.width = 400;
                    canvas.height = 350;
                    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                    const resizedDataUrl = canvas.toDataURL("image/jpeg");
                    setArquivo(resizedDataUrl);
                  };
                };
              }}
            />

            <h6
              style={{
                textAlign: "left",
                width: "300px",
              }}
            >
              Notificações habilitadas:
            </h6>
            <label>
              <input
                type="checkbox"
                checked={notificacaoHabilitada}
                onChange={handleCheckboxChange}
              />
              {notificacaoHabilitada ? "Habilitado" : "Desabilitado"}
            </label>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={loading}
            >
              {sessionStorage.getItem("ambienteEdicao") !== "{}"
                ? "Editar"
                : "Cadastrar"}
            </Button>

            <div
              className="mt-5"
              style={{ color: "#486ADC" }}
              onClick={() => (window.location.href = "/configuracao/ambientes")}
            >
              Fechar
            </div>
            <Fade in={loading}>
              <CircularProgress />
            </Fade>
          </form>
        </GridBox>
      </div>
    </Fragment>
  );
};

export default AmbienteForm;
